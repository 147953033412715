// src/App.js
import React from "react";
import "./App.css";
import AppRoutes from "./App.Routes";
import "bootstrap-icons/font/bootstrap-icons.css";
import { LanguageProvider } from "./components/LanguageContext";

const App = () => {
  return (
    <LanguageProvider>
      <div className="App">
        
        <AppRoutes />
      </div>
    </LanguageProvider>
  );
};

export default App;
