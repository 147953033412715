
import React, { useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLanguage } from "../LanguageContext";
import AOS from "aos";
import "../../assets/css/style.css";
import developer from "../../assets/img/developer.png";
import engineer from "../../assets/img/engineer.png";
import salesofficer from "../../assets/img/salesofficer.png";
const RecruitSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);

const { translate } = useLanguage();

  return (
    <section id="recruit" className="services section-bg">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2 className="recruit-title" style={{ textAlign: "center" }}>
            {translate("Home page slide3")}
          </h2>
        </div>

        <Row>
          <Col
            lg="4"
            md="6"
            className="d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box iconbox-blue">
              <a
                href="/recruit/system-engineer"
                className="newsLink text-decoration-none"
                style={{ color: "black", textDecoration: "none" }}
              >
                <div className="icon">
                  <img
                    src={developer}
                    width="80"
                    height="80"
                    alt="System Development"
                  />
                </div>
                <h4 style={{ color: "black" }}>
                  {translate("System Development")}
                </h4>
              </a>
            </div>
          </Col>

          <Col
            lg="4"
            md="6"
            className="d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box iconbox-orange">
              <a
                href="/recruit/infra-engineer"
                className="newsLink text-decoration-none"
                style={{ color: "black", textDecoration: "none" }}
              >
                <div className="icon">
                  <img
                    src={engineer}
                    width="80"
                    height="80"
                    alt="Infrastructure Engineer"
                  />
                </div>
                <h4 style={{ color: "black" }}>
                  {translate("Infrastructure Engineer")}
                </h4>
              </a>
            </div>
          </Col>

          <Col
            lg="4"
            md="6"
            className="d-flex align-items-stretch mt-4 mt-lg-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box iconbox-pink">
              <a
                href="/recruit/office-sale"
                className="newsLink text-decoration-none"
                style={{ color: "black", textDecoration: "none" }}
              >
                <div className="icon">
                  <img
                    src={salesofficer}
                    width="80"
                    height="80"
                    alt="Office & Sale"
                  />
                </div>
                <h4 style={{ color: "black" }}>{translate("Office&Sale")}</h4>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RecruitSection;
