/* eslint-disable react/prop-types */
import LabelComponent from "./LabelComponent";

export const ContractualComponent = (props) => {
  return (
    <div className="form-col1">
      <LabelComponent labelName={props.labelName} />
      <div className="form-row2 form-inputRadio">
        <div className="form-col6">
          <label htmlFor="seyi">
            <input
              type="radio"
              value="正社員"
              name="employeeType"
              id="seyi"
              defaultChecked={props.value}
              onChange={props.onChange}
            />
            <span className="form-radio-text">Permanent</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="keyiyaku">
            <input
              type="radio"
              value="契約社員"
              name="employeeType"
              id="keyiyaku"
              defaultChecked={props.value}
              onChange={props.onChange}
            />
            <span className="form-radio-text">Contract</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="haken">
            <input
              type="radio"
              value="派遣社員"
              name="employeeType"
              id="haken"
              // eslint-disable-next-line react/prop-types
              defaultChecked={props.value}
              // eslint-disable-next-line react/prop-types
              onChange={props.onChange}
            />
            <span className="form-radio-text">Temporary</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="gyoumu">
            <input
              type="radio"
              value="業務委託"
              name="employeeType"
              id="gyoumu"
              defaultChecked={props.value}
              onChange={props.onChange}
            />
            <span className="form-radio-text">Subcontracting</span>
          </label>
        </div>
      </div>
    </div>
  );
};
