
import React from "react";
// import { Container } from "react-bootstrap";
import "../../assets/css/style.css";
const VideoSection = () => {

   return (
     <section>
       <div className="rt-container">
         <div className="col-rt-12">
           <video
             src='https://kizuna-s3.s3.ap-northeast-1.amazonaws.com/home.mp4'
             type="video/mp4"
             playsInline
             autoPlay
             muted
             loop
           >
             Home
           </video>
           <div className="overlay">
             <a href="#service" className="btn">
               <i className="bi bi-arrow-down-circle-fill"></i>
             </a>
           </div>
         </div>
       </div>
     </section>
   );
};

export default VideoSection;
