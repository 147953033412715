
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import { useLanguage } from "../components/LanguageContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../assets/css/style.css";
const Footer = () => {
 const { translate } = useLanguage();


useEffect(() => { 
AOS.init({
  duration: 500,
  easing: "ease-in-out",
});
   }, []);

  return (
    <footer id="footer" data-aos="fade-up">
      <div className="footer-top">
        <Container>
          <Row className="footerStyle">
            <Col lg={2} className="mt-2 footer-links">
              <h4 className="ml-4">{translate("UsefulLinks")}</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="/" className="text-decoration-none">
                    {translate("nav-home")}
                  </a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="/services" className="text-decoration-none">
                    {translate("nav-services")}
                  </a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="/recruit" className="text-decoration-none">
                    {translate("nav-recruit")}
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={2} className="mt-2 footer-links">
              <h4>{translate("nav-companyInfo")}</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="/companyInfo" className="text-decoration-none">
                    {translate("nav-companyInfo")}
                  </a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="/contact" className="text-decoration-none">
                    {translate("nav-contactUs")}
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={7} className="ml-10 footer-contact">
              <Row className="footerCmpAddressStyle">
                <Col lg={4} className="info footer">
                  <i className="bi bi-geo-alt"></i>
                  <br />
                  <br />
                  <br />
                  <p>
                    {translate("Location Data1")}
                    <br />
                    {translate("Location Data2")} {translate("Location Data3")}
                    <br />
                    {translate("Location Data4")}
                  </p>
                </Col>
                <Col lg={4} className="ml-9 info footer">
                  <i className="bi bi-envelope"></i>
                  <br />
                  <br />
                  <br />
                  <p>startech-kanri@startech.co.jp</p>
                  <br />
                </Col>
                <Col lg={4} className="ml-5 info footer">
                  <a
                    href="tel:03-6661-2879"
                    className="ft-contact text-decoration-none"
                  >
                    <i className="bi bi-phone"></i>
                    <p className="align-middle">
                      &nbsp;&nbsp;TEL : 03-6661-2879
                    </p>
                  </a>
                  <br />
                  <br />
                  <a href="#" className="ft-contact text-decoration-none">
                    <i className="bi bi-printer"></i>
                    <p className="align-middle">
                      &nbsp;&nbsp;FAX : 03-5207-2956
                    </p>
                    <br />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="ftCopyRight">
        <div className="copyright">
          &copy;{" Copyright "}
          <strong>
            <span>
              {translate("CompanyName")}
              {"."}
            </span>
          </strong>{" "}
          {"All Rights Reserved"}
        </div>
        <div className="credits"></div>
      </Container>
    </footer>
  );
};

export default Footer;
