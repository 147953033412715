/* eslint-disable react/prop-types */
const LabelComponent = (props) => {
  return (
    <label htmlFor="lastName1" className="form-label">
      {props.labelName}
      <span className="require"> *</span>
      {props.spanName}
    </label>
  );
};
export default LabelComponent;
