import React from "react";
import { useLanguage } from "../LanguageContext";

const MarginRate = () => {
  const { translate } = useLanguage();
  return (
    <div className="labour-section">
      <div className="section-title">
        <h2 className="labour-title" style={{ textAlign: "center" }}>
          {translate("cmpLabourTitle")}
        </h2>
      </div>
      <div className="labour-block">
        <p className="labour-info">
          {translate("cmpLabourInfo")}
          <span>{translate("cmpLabourInfoSpan")}</span>
        </p>
        <ul className="labour-list">
          <li>
            <p>{translate("cmpLabourListTtl1")}</p>
          </li>
          <li>
            <p>{translate("cmpLabourListTtl2")}</p>
            <table className="labour-tbl">
              <tr>
                <td>{translate("cmpLabourTbl1No")}</td>
                <td>{translate("cmpLabourTbl1Txt")}</td>
                <td>{translate("cmpLabourTbl1Cnt")}</td>
              </tr>
              <tr>
                <td>{translate("cmpLabourTbl2No")}</td>
                <td>{translate("cmpLabourTbl2Txt")}</td>
                <td>{translate("cmpLabourTbl2Cnt")}</td>
              </tr>
              <tr>
                <td>{translate("cmpLabourTbl3No")}</td>
                <td>{translate("cmpLabourTbl3Txt")}</td>
                <td>{translate("cmpLabourTbl3Cnt")}</td>
              </tr>
              <tr>
                <td>{translate("cmpLabourTbl4No")}</td>
                <td>{translate("cmpLabourTbl4Txt")}</td>
                <td>{translate("cmpLabourTbl4Cnt")}</td>
              </tr>
              <tr>
                <td>{translate("cmpLabourTbl5No")}</td>
                <td>
                  {translate("cmpLabourTbl5Txt")}
                  <span className="labour-spantxt">
                    {translate("cmpLabourTbl5Txtspan")}
                  </span>
                </td>
                <td>{translate("cmpLabourTbl5Cnt")}</td>
              </tr>
            </table>
          </li>
          <li>
            <p>{translate("cmpLabourListTtl3")}</p>
            <ul className="labour-sublst">
              <li>{translate("cmpLaboursublist01")}</li>
              <li>{translate("cmpLaboursublist02")}</li>
              <li>{translate("cmpLaboursublist03")}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MarginRate;
