import LabelComponent from "./LabelComponent";

/* eslint-disable react/prop-types */
const PhoneComponent = (props) => {
  return (
    <div className="form-col2">
      <LabelComponent labelName={props.labelName} />
      <div className="form-inputText">
        <input
          type="text"
          className="inputType"
          name="denwabangou"
          id="denwabangou"
          placeholder={props.placeholder}
          defaultValue={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
          minLength={props.minLength}
        />
      </div>
    </div>
  );
};

export default PhoneComponent;
