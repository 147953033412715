import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./includes/Header";
import HeadContent from "./includes/HeadContent";
import ScrollToTopButton from "./components/common/ScrollToTopButton";
import Home from "./components/home-components/Home";
import CompanyInfo from "./components/companyinfo-components/CompanyInfo";
import Recruit from "./components/recruit-components/Recruit";
import InformationSecurity from "./components/information-security-components/InformationSecurity";
import Contact from "./components/contact-components/Contact";
import Footer from "./includes/Footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import SystemEngineer from "./components/recruit-components/SystemEngineer";
import SystemEngineerForm from "./components/system-engineer-components/SystemEngineerForm";
import InfraEngineer from "./components/recruit-components/InfraEngineer";
import InfraEntryForm from "./components/infra-engineer-components/InfraEntryForm";
import OfficeAndSaleRecruit from "./components/recruit-components/OfficeAndSaleRecruit";
import OfficeEntryForm from "./components/officesale-components/OfficeEntryForm";
import ServicePage from "./components/services-components/ServicePage";
import "bootstrap-icons/font/bootstrap-icons.css";
function AppRoutes() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeadContent pageTitle="STARTECH株式会社" />
              <Home />
            </>
          }
        />

        <Route
          path="/companyInfo"
          element={
            <>
              <HeadContent pageTitle="companyInfo" />
              <CompanyInfo />
            </>
          }
        />

        <Route
          path="/services"
          element={
            <>
              <HeadContent pageTitle="services" />
              <ServicePage />
            </>
          }
        />
        <Route
          path="/recruit"
          element={
            <>
              <HeadContent pageTitle="recruit" />
              <Recruit />
            </>
          }
        />
        <Route
          path="/SystemEngineerForm"
          element={
            <>
              <HeadContent pageTitle="recruit" />
              <SystemEngineerForm />
            </>
          }
        />
        <Route
          path="/recruit/system-engineer"
          element={
            <>
              <HeadContent pageTitle="recruit" />
              <SystemEngineer />
            </>
          }
        />
        <Route
          path="/recruit/infra-engineer"
          element={
            <>
              <HeadContent pageTitle="recruit" />
              <InfraEngineer />
            </>
          }
        />

        <Route
          path="/InfraEntryForm"
          element={
            <>
              <HeadContent pageTitle="InfraEntry" />
              <InfraEntryForm />
            </>
          }
        />
        <Route
          path="/OfficeEntryForm"
          element={
            <>
              <HeadContent pageTitle="OfficeEntryForm" />
              <OfficeEntryForm />
            </>
          }
        />
        <Route
          path="/informationSecurity"
          element={
            <>
              <HeadContent pageTitle="informationSecurity" />
              <InformationSecurity />
            </>
          }
        />

        <Route
          path="/contact"
          element={
            <>
              <HeadContent pageTitle="contact" />
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
      <ScrollToTopButton />
    </Router>
  );
}

export default AppRoutes;
