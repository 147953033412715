
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLanguage } from "../LanguageContext";
import AOS from "aos";
import "../../assets/css/style.css";

const ServiceSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
const { translate } = useLanguage();

  return (
    <section id="service" className="blog">
      <Container data-aos="fade-up">
        <div className="section-title pt-3" data-aos="fade-up">
          <h2 className="p-3">{translate("KigyourinenTitle")}</h2>
        </div>
        <Row>
          <Col md="12" className="ceoMsgDv entries" data-aos="fade-up">
            <div className="ceoMsgDv-cover">
              <div className="ceoMsg">
                <p className="ceoMessage">{translate("Kigyorinen1")}</p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("Kigyorinen1-1")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("Kigyorinen1-2")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("Kigyorinen1-3")}
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="section-title" data-aos="fade-up">
          <h2>{translate("CEO Message")}</h2>
        </div>

        <Row>
          <Col md="12" className="ceoMsgDv entries" data-aos="fade-up">
            <div className="ceoMsgDv-cover">
              <div className="ceoMsg">
                <p className="ceoMessage">{translate("CEO Message data2")}</p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data3")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data4")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data5")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data6")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data7")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data8")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data9")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data10")}
                </p>
                <p className="ceoMessage" id="ceoMsgPstyle">
                  {translate("CEO Message data11")}
                </p>

                <p className="ceoMsgDate">
                  <span>{translate("CEOMsgDate")}</span>
                </p>
                <p className="ceoName">
                  {translate("ceoPosition")} <span>{translate("CEOName")}</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="section-title" style={{ paddingBottom: "0px" }}>
          <h2>{translate("Home News")}</h2>
        </div>

        <Row>
          <Col lg="12" className="entries">
            <article
              className="entry"
              data-aos="fade-up"
              style={{
                boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.12)",
                height: "max-content",
              }}
            >
              <div className="newsBlock cFix">
                <ul>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <a href="#" className="newsLink text-decoration-none">
                      <span className="newsDate">{translate("date1")}</span>
                      <span
                        className="newsTtl"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {translate("date1 news")}
                      </span>
                    </a>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <a href="#" className="newsLink text-decoration-none">
                      <span className="newsDate">{translate("date2")}</span>
                      <span
                        className="newsTtl"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {translate("date2 news")}
                      </span>
                    </a>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <a href="#" className="newsLink text-decoration-none">
                      <span className="newsDate">{translate("date3")}</span>
                      <span
                        className="newsTtl"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {translate("date3 news")}
                      </span>
                    </a>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <a href="#" className="newsLink text-decoration-none">
                      <span className="newsDate">{translate("date4")}</span>
                      <span
                        className="newsTtl"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {translate("date4 news")}
                      </span>
                    </a>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <a href="#" className="newsLink text-decoration-none">
                      <span className="newsDate">{translate("date5")}</span>
                      <span
                        className="newsTtl"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {translate("date5 news")}
                      </span>
                    </a>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="50">
                    <a href="#" className="newsLink text-decoration-none">
                      <span className="newsDate">{translate("date6")}</span>
                      <span
                        className="newsTtl"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {translate("date6 news")}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </article>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceSection;
