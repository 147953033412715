import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';

const OfficeEntryForm = () => {
    const { translate } = useLanguage();
    const [formData, setFormData] = useState({
        lastName1: '',
        firstName1: '',
        lastName2: '',
        firstName2: '',
        dob: '',
        email: '',
        denwabangou: '',
        address: '',
        salaryfrom: '',
        salaryto: '',
        employeeType: '正社員', // Default value for radio buttons
        profession: [], // Array for checkboxes
        remark: '',
        chkagree: false,
    });

    const [errors, setErrors] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            if (name === 'profession') {
                // Clone the current profession array
                let updatedProfessions = [...formData.profession];

                if (checked) {
                    // Add the checked profession to the array if it's not already there
                    if (!updatedProfessions.includes(value)) {
                        updatedProfessions.push(value);
                    }
                } else {
                    // Remove the unchecked profession from the array
                    updatedProfessions = updatedProfessions.filter((prof) => prof !== value);
                }

                // Update the form data state for professions
                setFormData(prevState => ({
                    ...prevState,
                    profession: updatedProfessions
                }));
            } else {
                // For other checkboxes like chkagree
                setFormData(prevState => ({
                    ...prevState,
                    [name]: checked
                }));
            }
        } else {
            // For other input types (text, date, etc.), update the form data normally
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form data
        const validationErrors = validateFormData(formData);
        setErrors(validationErrors);

        if (validationErrors.length === 0) {
            console.log('Form Data:', formData);

            // Reset form fields
            setFormData({
                lastName1: '',
                firstName1: '',
                lastName2: '',
                firstName2: '',
                dob: '',
                email: '',
                denwabangou: '',
                address: '',
                salaryfrom: '',
                salaryto: '',
                employeeType: '正社員',
                profession: [],
                remark: '',
                chkagree: false,
            });

            // Clear errors after successful submission
            setErrors([]);
        } else {

            window.scrollTo(0, 0);
        }
    };


    const validateFormData = (data) => {
        let errors = [];

        // Validate last name and first name fields
        if (!data.lastName1) {
            errors.push({ message: translate("lastName1-required") });
        }
        if (data.lastName1.length > 20) {
            errors.push({ message: translate("lastName1-max") });
        }
        if (!data.firstName1) {
            errors.push({ message: translate("firstName1-required") });
        }
        if (data.firstName1.length > 20) {
            errors.push({ message: translate("firstName1-max") });
        }
        if (!data.lastName2) {
            errors.push({ message: translate("lastName2-required") });
        }
        if (data.lastName2.length > 20) {
            errors.push({ message: translate("lastName2-max") });
        }
        if (!data.firstName2) {
            errors.push({ message: translate("firstName2-required") });
        }
        if (data.firstName2.length > 20) {
            errors.push({ message: translate("firstName2-max") });
        }

        // Validate email
        if (!data.email) {
            errors.push({ message: translate("email-required") });
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(data.email)) {
                errors.push({ message: translate("email-email") });
            }
        }

        // Validate date of birth
        if (!data.dob) {
            errors.push({ message: translate("dob-required") });
        } else {
            const dobRegex = /^(0[1-9]|1[0-2]) (0[1-9]|[12][0-9]|3[01]) \d{2}$/;
            if (!dobRegex.test(data.dob)) {
                errors.push({ message: translate("dob-type") });
            }
            const dobParts = data.dob.split(" ");
            const enteredDate = new Date(`20${dobParts[2]}`, dobParts[0] - 1, dobParts[1]);
            const minAgeDate = new Date();
            minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);
            if (enteredDate > minAgeDate) {
                errors.push({ message: translate("dob-before20") });
            }
        }

        // Validate phone number
        if (!data.denwabangou) {
            errors.push({ message: translate("denwabangou-required") });
        } else {
            const phoneRegex = /^\d+$/;
            if (!phoneRegex.test(data.denwabangou)) {
                errors.push({ message: translate("denwabangou-numeric") });
            }
            if (data.denwabangou.length > 20) {
                errors.push({ message: translate("denwabangou-length") });
            }
        }

        // Validate address
        if (!data.address) {
            errors.push({ message: translate("address-required") });
        } else if (data.address.length > 50) {
            errors.push({ message: translate("address-max") });
        }
        // Validate salary from
        if (!data.salaryfrom && !(/^\d+$/.test(data.salaryfrom))) {
            errors.push({ message: translate("salaryfrom-numeric") });
        }

        // Validate salary to
        if (!data.salaryto && !(/^\d+$/.test(data.salaryto))) {
            errors.push({ message: translate("salaryto-numeric") });
        }


        // Validate skillsheet
        if (!data.skillsheet) {
            errors.push({ message: translate("skillsheet-required") });
        } else if (data.skillsheet.length > 100) {
            errors.push({ message: translate("skillsheet-max") });
        }

        // Validate profession
        if (!data.profession || data.profession.length === 0) {
            errors.push({ message: translate("profession") });
        }

        // Validate remark
        if (!data.remark) {
            errors.push({ message: translate("remark-required") });
        } else if (data.remark.length < 0 || data.remark.length > 400) {
            errors.push({ message: translate("remark-max") });
        }

        // Validate agreement checkbox
        if (!data.chkagree) {
            errors.push({ message: translate("chkagree-required") });
        }

        return errors;
    };


    return (
        <main id="main">
            {/* Breadcrumbs */}
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <strong><h2>{translate("Office&Sale")}</h2></strong>
                        <ol>
                            <li>
                                <a href="/">{translate("nav-home")}</a>
                            </li>
                            <li>
                                <a href="/recruit">{translate("Recruit")}</a>
                            </li>
                            <li>{translate("Office&Sale")}</li>
                        </ol>
                    </div>
                </div>
            </section>

            {/* Form Section */}
            <section className="contact">
                <div className="container">
                    <div className="row mt-1 justify-content-center" data-aos="fade-up">
                        <div className="col-lg-8" id="error-div">
                            {errors.length > 0 && (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {errors.length > 0 && (
                                        <ul className="error-list">
                                            {errors.map((error, index) => (
                                                <li key={index}>{error.message}</li>
                                            ))}
                                        </ul>
                                    )}
                                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setErrors([])}></button>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-8">
                            <form onSubmit={handleSubmit} className="php-email-form" id="entry-system-engineer-form" encType="multipart/form-data">
                                {/* CSRF Token */}
                                <input type="hidden" name="_token" value="{{ csrf_token() }}" />

                                {/* Full Name - Kanji */}
                                <div className="form-row form-mb">
                                    <div className="form-col2">
                                        <label htmlFor="lastName1" className="form-label">
                                            {translate("Full Name")} <span className="require">*</span>{translate("Kanji")}
                                        </label>
                                        <div className="form-inputText">
                                            <input type="text" className="inputType" name="lastName1" id="lastName1" placeholder={translate("lastName1")} value={formData.lastName1} onChange={handleChange} />
                                            <input type="text" className="inputType" name="firstName1" placeholder={translate("firstName1")} value={formData.firstName1} onChange={handleChange} />
                                        </div>
                                    </div>
                                    {/* Full Name - Katakana */}
                                    <div className="form-col2">
                                        <label htmlFor="lastName2" className="form-label">
                                            {translate("Full Name")} <span className="require">*</span>{translate("Katakana")}
                                        </label>
                                        <div className="form-inputText">
                                            <input type="text" className="inputType" name="lastName2" id="lastName2" placeholder={translate("lastName2")} value={formData.lastName2} onChange={handleChange} />
                                            <input type="text" className="inputType" name="firstName2" placeholder={translate("firstName2")} value={formData.firstName2} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                {/* Date of Birth */}
                                <div className="form-row form-mb">
                                    <div className="form-col2">
                                        <label htmlFor="dob" className="form-label">
                                            {translate("Date of Birth")} <span className="require">*</span>
                                        </label>
                                        <div className="form-inputText">
                                            <input type="date" className="inputType" name="dob" id="dob" value={formData.dob} onChange={handleChange} />
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className="form-col2">
                                        <label htmlFor="email" className="form-label">
                                            {translate("Your Email")} <span className="require">*</span>
                                        </label>
                                        <div className="form-inputText">
                                            <input type="text" className="inputType" name="email" id="email" placeholder={translate("email-placeholder")} value={formData.email} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                {/* Phone Number */}
                                <div className="form-row form-mb">
                                    <div className="form-col2">
                                        <label htmlFor="denwabangou" className="form-label">
                                            {translate("Phone Number")} <span className="require">*</span>
                                        </label>
                                        <div className="form-inputText">
                                            <input type="text" className="inputType" name="denwabangou" id="denwabangou" placeholder={translate("denwabangou")} value={formData.denwabangou} onChange={handleChange} />
                                        </div>
                                    </div>

                                    {/* Address */}
                                    <div className="form-col2">
                                        <label htmlFor="address" className="form-label">
                                            {translate("Address")} <span className="require">*</span>
                                        </label>
                                        <div className="form-inputText">
                                            <input type="text" className="inputType" name="address" id="address" value={formData.address} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                {/* Desired Monthly Amount */}
                                <div className="form-row form-mb">
                                    <div className="form-col2">
                                        <label htmlFor="salaryfrom" className="form-label">
                                            {translate("Desired Monthly Amount")} <span className="require">*</span>
                                        </label>
                                        <div className="form-inputText input-salary">
                                            <input type="text" className="inputType" name="salaryfrom" id="salaryfrom" value={formData.salaryfrom} onChange={handleChange} />
                                            <span className="form-sign">～</span>
                                            <input type="text" className="inputType" name="salaryto" id="salaryto" value={formData.salaryto} onChange={handleChange} />
                                            <span className="form-yern">{translate("Yen")}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Desired Contract Form */}
                                <div className="form-row form-mb">
                                    <div className="form-col1">
                                        <label className="form-label">
                                            {translate("Desired Contract Form")} <span className="require">*</span>
                                        </label>
                                        <div className="form-row2 form-inputRadio">
                                            <div className="form-col6">
                                                <label htmlFor="seyi">
                                                    <input type="radio" value="正社員" name="employeeType" id="seyi" checked={formData.employeeType === '正社員'} onChange={handleChange} />
                                                    <span className="form-radio-text">{translate("Permanent")}</span>
                                                </label>
                                            </div>
                                            <div className="form-col6">
                                                <label htmlFor="keyiyaku">
                                                    <input type="radio" value="契約社員" name="employeeType" id="keyiyaku" checked={formData.employeeType === '契約社員'} onChange={handleChange} />
                                                    <span className="form-radio-text">{translate("Contract")}</span>
                                                </label>
                                            </div>
                                            <div className="form-col6">
                                                <label htmlFor="haken">
                                                    <input type="radio" value="派遣社員" name="employeeType" id="haken" checked={formData.employeeType === '派遣社員'} onChange={handleChange} />
                                                    <span className="form-radio-text">{translate("Temporary")}</span>
                                                </label>
                                            </div>
                                            <div className="form-col6">
                                                <label htmlFor="gyoumu">
                                                    <input type="radio" value="業務委託" name="employeeType" id="gyoumu" checked={formData.employeeType === '業務委託'} onChange={handleChange} />
                                                    <span className="form-radio-text">{translate("Subcontracting")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Profession */}
                                <div className="form-col1">
                                    <label className="form-label">
                                        {translate("Profession")} <span className="require">*</span>
                                    </label>
                                    <div className="form-row2 form-inputCheckbox">
                                        <div className="form-col6 mt-2">
                                            <label htmlFor="eyigyo">
                                                <input type="checkbox" value="営業" name="profession" id="eyigyo" checked={formData.profession.includes('営業')} onChange={handleChange} />
                                                <span className="form-checkbox">{translate("Office work")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col6 mt-2">
                                            <label htmlFor="zimu">
                                                <input type="checkbox" value="事務" name="profession" id="zimu" checked={formData.profession.includes('事務')} onChange={handleChange} />
                                                <span className="form-checkbox">{translate("Sales")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Skills Sheet */}
                                <div className="form-row form-mb mt-4">
                                    <div className="form-col1 mt-2">
                                        <label htmlFor="skillsheet" className="form-label">
                                            {translate("Skills Sheet")} <span className="require">*</span>
                                        </label>
                                        <div className="form-row form-inputFile">
                                            <div className="form-col2">
                                                <input type="file" className="inputFile" name="skillsheet" id="skillsheet" accept=".xlsx,.xls,.csv,.docx,.word,.pdf" multiple onChange={handleChange} />
                                            </div>
                                            <div className="form-col2">
                                                <p className="file-para">
                                                    ※{translate("Supported Format")}：「xlsx、xls、csv、docx、word、pdf」
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Remarks */}
                                <div className="form-row form-mb">
                                    <div className="form-col1">
                                        <label htmlFor="inquiry_area" className="form-label">
                                            {translate("Remarks")} <span className="require">*</span>
                                        </label>
                                        <p className="show_message">
                                            <span className="" id=""></span>
                                        </p>
                                        <div className="form-textarea">
                                            <textarea name="remark" cols="50" rows="5" className="inquiry_area" id="inquiry_area" placeholder={translate("Please enter within 400 characters")} value={formData.remark} onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                </div>

                                {/* Privacy Handling Section */}



                                <div className="form-row form-mb">
                                    <div className="form-col1">
                                        <div className="form-inputCheckbox">
                                            <div className="form-privacy">
                                                <h3 className="form-privacy-ttl">
                                                    {translate("Handling of personal info")}
                                                </h3>
                                                <p>
                                                    {translate("We handle personal information entrusted to us as follows.")}
                                                </p>
                                                <p className="pri-num">
                                                    <span>1.</span>
                                                    {translate("About the purpose of use")}<br />
                                                    {translate("Purpose_personalInfo_used")}<br />
                                                    ・{translate("Personal_info_used")}<br />
                                                    ・{translate("Purpose of use")}
                                                </p>
                                                <p className="pri-num">
                                                    <span>2.</span>
                                                    {translate("About personal")}<br />
                                                    {translate("Trade Name Data")}<br />
                                                    {translate("Personal information protection manager")}
                                                    {translate("CEOName")}<br />
                                                    {translate("Address")}:
                                                    {translate("Location Data1")}
                                                    {translate("Location Data2")}
                                                    {translate("Location Data3")}
                                                    {translate("Location Data4")}<br />
                                                    {translate("call")}: 03-6661-2879
                                                </p>
                                                <p className="pri-num">
                                                    <span>3.</span>
                                                    {translate("About consignment")}<br />
                                                    {translate("We may outsource")}
                                                </p>
                                                <p className="pri-num">
                                                    <span>4.</span>
                                                    {translate("About optional")}<br />
                                                    {translate("It is optional")}
                                                </p>
                                                <p className="pri-num">
                                                    <span>5.</span>
                                                    {translate("About complaints")}<br />
                                                    {translate("About complaints body")}<br />
                                                    {translate("A fee")}
                                                </p>
                                            </div>
                                            <label htmlFor="chkagree" className="mt-20">
                                                <input
                                                    type="checkbox"
                                                    name="chkagree"
                                                    id="chkagree"
                                                    checked={formData.chkagree}
                                                    onChange={handleChange}
                                                />
                                                <span className="form-checkbox col-red chkagree-text">
                                                    {translate("Handling of personal information")}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* Submission status messages */}
                                <div className="my-3">
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">
                                        Your message has been sent. Thank you!
                                    </div>
                                </div>



                                {/* Submit button */}
                                <div className="form-row">
                                    <div className="form-col1">
                                        <div className="text-center">
                                            <button type="submit" className="btn02" id="send_mail">
                                                {translate("Send")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* Loading overlay */}
            <div id="loading-overlay" className="d-none">
                <div className="animation">
                    <div className="i-mail">
                        <div className="mail-anim"></div>
                    </div>
                    <div className="line"></div>
                    <div className="i-success">
                        <div className="success-anim"></div>
                    </div>
                </div>
                <div className="loading-container">
                    <div className="loading"></div>
                    <div id="loading-text">{translate("sending-mail")}</div>
                </div>
            </div>
        </main>
    );
};

export default OfficeEntryForm;
