/* eslint-disable react/prop-types */
const OSComponent = (props) => {
  return (
    <div className="form-col1 mt-2 file-para mt-3">
      <label htmlFor="form-label">
        OS
        <span className="require">*</span>
      </label>
      <div className="form-row2 form-inputCheckbox">
        <div className="form-col6">
          <label htmlFor="Window">
            <input
              type="checkbox"
              value="Window"
              name="OS"
              id="Window"
              defaultChecked={props.values[0]}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Windows</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Linux">
            <input
              type="checkbox"
              value="Linux"
              name="OS"
              id="Linux"
              defaultChecked={props.values[1]}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Linux</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Mac">
            <input
              type="checkbox"
              value="Mac"
              name="OS"
              id="Mac"
              defaultChecked={props.values[2]}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Mac</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="AIX">
            <input
              type="checkbox"
              value="AIX"
              name="OS"
              id="AIX"
              defaultChecked={props.values[3]}
              onChange={props.onChange}
            />
            <span className="form-checkbox">AIX</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default OSComponent;
