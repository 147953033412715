import LabelComponent from "./LabelComponent";

/* eslint-disable react/prop-types */
const DOBComponent = (props) => {
  return (
    <div className="form-col2">
      <LabelComponent labelName={props.labelName} />
      <div className="form-inputText">
        <input
          type="date"
          className="inputType"
          name="dob"
          id="selectYear"
          placeholder=""
          defaultValue={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export default DOBComponent;
