/* eslint-disable react/prop-types */
import LabelComponent from "./LabelComponent";

const DBComponent = (props) => {
  return (
    <div className="form-col1">
      <LabelComponent labelName={props.labelName} />
      <div className="form-row2 form-inputCheckbox">
        <div className="form-col6">
          <label htmlFor="Mysql">
            <input
              type="checkbox"
              value="Mysql"
              name="DB[]"
              id="Mysql"
              defaultChecked={props.db.Mysql}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Mysql</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="MariaDB">
            <input
              type="checkbox"
              value="MariaDB"
              name="DB[]"
              id="MariaDB"
              defaultChecked={props.db.MariaDB}
              onChange={props.onChange}
            />
            <span className="form-checkbox">MariaDB</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="SQLite">
            <input
              type="checkbox"
              value="SQLite"
              name="DB[]"
              id="SQLite"
              defaultChecked={props.db.SQLite}
              onChange={props.onChange}
            />
            <span className="form-checkbox">SQLite</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Oracle">
            <input
              type="checkbox"
              value="Oracle"
              name="DB[]"
              id="Oracle"
              defaultChecked={props.db.Oracle}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Oracle</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="PostgreSQL">
            <input
              type="checkbox"
              value="PostgreSQL"
              name="DB[]"
              id="PostgreSQL"
              defaultChecked={props.db.PostgreSQL}
              onChange={props.onChange}
            />
            <span className="form-checkbox">PostgreSQL</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="SQLServer">
            <input
              type="checkbox"
              value="SQLServer"
              name="DB[]"
              id="SQLServer"
              defaultChecked={props.db.SQLServer}
              onChange={props.onChange}
            />
            <span className="form-checkbox">SQL Server</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="DB2">
            <input
              type="checkbox"
              value="DB2"
              name="DB[]"
              id="DB2"
              defaultChecked={props.db.DB2}
              onChange={props.onChange}
            />
            <span className="form-checkbox">DB2</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="PL/SQL">
            <input
              type="checkbox"
              value="PL/SQL"
              name="DB[]"
              id="PL/SQL"
              defaultChecked={props.db.PL}
              onChange={props.onChange}
            />
            <span className="form-checkbox">PL/SQL</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Access">
            <input
              type="checkbox"
              value="Access"
              name="DB[]"
              id="Access"
              defaultChecked={props.db.Access}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Access</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="otherDB">
            <input
              type="checkbox"
              value="otherDB"
              name="DB[]"
              id="otherDB"
              defaultChecked={props.db.Other}
              onChange={props.onChange}
            />
            <span className="form-checkbox">その他</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default DBComponent;
