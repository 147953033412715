import React, { createContext, useContext, useState } from "react";
import en from "../resources/lang/en.json"; // English translations
import jp from "../resources/lang/jp.json"; // Japanese translations

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {

   const [language, setLanguage] = useState('jp'); // Default to Japanese

  const toggleLanguage = (lang) => {
    setLanguage(lang);
  };


  const translate = (key) => {
    const translations = {
      en: en[key],
      jp: jp[key],
    };
    return translations[language];
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};
