import $ from "jquery";

$(document).ready(function () {
  $(".nav li:first").addClass("active");
  $(".tab-content:not(:first)").hide();
  $(".nav li a").click(function (event) {
    event.preventDefault();
    var content = $(this).attr("href");
    $(".nav li a").removeClass("active");
    $(this).parent().addClass("active");
    $(this).parent().siblings().removeClass("active");
    $(content).show();
    $(content).siblings(".tab-content").hide();
    $(".collapse").collapse("hide");
  });
});
