/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import AddressComponent from "../system-engineer-components/sub-components/AddressComponent";
import { ContractualComponent } from "../system-engineer-components/sub-components/ContractualComponent";
import DBComponent from "../system-engineer-components/sub-components/DBComponent";
import DOBComponent from "../system-engineer-components/sub-components/DOBComponent";
import EmailComponent from "../system-engineer-components/sub-components/EmailComponent";
import FullNameComponent from "../system-engineer-components/sub-components/FullNameComponent";
import InformationComponent from "../system-engineer-components/sub-components/InformationComponent";
import LanguageComponent from "../system-engineer-components/sub-components/LanguageComponent";
import NavigationBarComponent from "../system-engineer-components/sub-components/NavigationBarComponent";
import OSComponent from "../system-engineer-components/sub-components/OSComponent";
import PhoneComponent from "../system-engineer-components/sub-components/PhoneComponent";
import RemarkComponent from "../system-engineer-components/sub-components/RemarkComponent";
import SalaryComponent from "../system-engineer-components/sub-components/SalaryComponent";
import SkillSheetComponent from "../system-engineer-components/sub-components/SkillSheetComponent";
import { useLanguage } from "../../components/LanguageContext";
import {
  closeErrorMsgList,
  initializePage,
} from "../../assets/js/email_success.js";
import $, { error } from "jquery";

const SystemEngineerForm = () => {
  // Email Jquery Initialize
  useEffect(() => {
    initializePage();
  }, []);
  //-------------- variable list -----------------------
  const { translate } = useLanguage();
  const [formData, setFormData] = useState({
    lastName1: "",
    firstName1: "",
    lastName2: "",
    firstName2: "",
    dob: "",
    email: "",
    denwabangou: "",
    address: "",
    salaryfrom: "",
    salaryto: "",
    employeeType: "",
    OS: {
      Window: false,
      Linux: false,
      Mac: false,
      AIX: false,
    },
    Language: {
      Java: false,
      PHP: false,
      Python: false,
      C: false,
      Ruby: false,
      Net: false,
      Javascript: false,
      VisualBasic: false,
      Android: false,
      SalesForce: false,
      IOS: false,
      Other: false,
    },
    DB: {
      Mysql: false,
      MariaDB: false,
      SQLite: false,
      Oracle: false,
      PostgreSQL: false,
      SQLServer: false,
      DB2: false,
      PL: false,
      Access: false,
      Other: false,
    },
    skillsheet: "",
    remark: "",
    chkagree: false,
  });
  const [errors, setErrors] = useState({
    firstName1: "",
    firstName2: "",
    lastName1: "",
    lastName2: "",
    dob: ["", ""],
    email: "",
    phone: ["", ""],
    address: "",
    salary: ["", ""],
    skillsheet: ["", ""],
    remark: "",
    agree: "",
  });
  //-------------- function list  -----------------------
  //input handle
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name + " " + value + " " + type + " " + checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type == "checkbox" ? checked : value,
    }));
  };

  const handleOs = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name + "-" + value + " " + type + " " + checked);
    setFormData((preForm) => ({
      ...preForm,
      OS: {
        ...preForm.OS,
        [value]: checked,
      },
    }));
  };
  const handleLanguage = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name + " " + value + " " + type + " " + checked);
    setFormData((preForm) => ({
      ...preForm,
      Language: {
        ...preForm.Language,
        [value]: checked,
      },
    }));
  };

  const handleDB = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name + " " + value + " " + type + " " + checked);
    setFormData((preForm) => ({
      ...preForm,
      DB: {
        ...preForm.DB,
        [value]: checked,
      },
    }));
  };
  const OScheck = Object.values(formData.OS).every((value) => value == false);
  const DBcheck = Object.values(formData.DB).every((value) => value == false);
  const Langcheck = Object.values(formData.Language).every(
    (value) => value == false
  );
  const requiredCheck = (value) => value == "";
  const onSubmitForm = (e) => {
    e.preventDefault();

    const check = errorsCheck();
    console.log(fileTypeCheck(formData.skillsheet));
    console.log(formData);
    if (check) {
      console.log(errors);
      $("#error-div").show();
    }
  };
  const errorsCheck = () => {
    let validate = false;
    const errorCopy = { ...errors };
    // firstName1 required
    if (requiredCheck(formData.firstName1)) {
      errorCopy.firstName1 = translate("firstName1-required");
      validate = true;
    } else {
      errorCopy.firstName1 = "";
    }
    // firstName2 required
    if (requiredCheck(formData.firstName2)) {
      errorCopy.firstName2 = translate("firstName2-required");
      validate = true;
    } else {
      errorCopy.firstName2 = "";
    }
    // lastName1 required
    if (requiredCheck(formData.lastName1)) {
      errorCopy.lastName1 = translate("lastName1-required");
      validate = true;
    } else {
      errorCopy.lastName1 = "";
    }
    // lastName2 required
    if (requiredCheck(formData.lastName2)) {
      errorCopy.lastName2 = translate("lastName2-required");
      validate = true;
    } else {
      errorCopy.lastName2 = "";
    }
    // dob required
    if (requiredCheck(formData.dob)) {
      errorCopy.dob[0] = translate("dob-required");
      validate = true;
    } else {
      errorCopy.dob[0] = "";
    }
    // before 20 age check
    if (!requiredCheck(formData.dob)) {
      errorCopy.dob[1] = translate("dob-before20");
      validate = true;
    } else {
      errorCopy.dob[1] = "";
    }
    if (requiredCheck(formData.email)) {
      errorCopy.email = translate("email-required");
      validate = true;
    } else {
      errorCopy.email = "";
    }
    // phone check
    if (requiredCheck(formData.denwabangou)) {
      errorCopy.phone[0] = translate("denwabangou-required");
      validate = true;
    } else {
      const check = numericCheck(formData.denwabangou);
      if (check) {
        errorCopy.phone = ["", ""];
      } else {
        errorCopy.phone[1] = translate("denwabangou-numeric");
        validate = true;
      }
    }
    // address required
    if (requiredCheck(formData.address)) {
      errorCopy.address = translate("address-required");
      validate = true;
    } else {
      errorCopy.address = "";
    }
    // salary
    if (requiredCheck(formData.salaryfrom)) {
      errorCopy.salary[0] = translate("salaryfrom-numeric");
      validate = true;
    } else {
      errorCopy.salary = [];
    }

    if (requiredCheck(formData.salaryto)) {
      errorCopy.salary[1] = translate("salaryto-numeric");
      validate = true;
    } else {
      errorCopy.salary = [];
    }
    if (requiredCheck(formData.skillsheet)) {
      errorCopy.skillsheet[0] = translate("skillsheet-required");
      validate = true;
    } else {
      if (!fileTypeCheck(formData.skillsheet)) {
        errorCopy.skillsheet[1] = translate("skillsheet-type");
      } else {
        errorCopy.skillsheet = [];
      }
    }
    if (requiredCheck(formData.remark)) {
      errorCopy.remark = translate("remark-required");
      validate = true;
    } else {
      errorCopy.remark = "";
    }
    if (!formData.chkagree) {
      errorCopy.agree = translate("chkagree-required");
      validate = true;
    } else {
      errorCopy.agree = "";
    }
    setErrors(errorCopy);
    return validate;
  };
  const numericCheck = (value) => /^\d+$/.test(value);
  const fileTypeCheck = (value) => {
    const [name, ext] = value.split(".");
    const check_ext = ["xlsx", "xls", "csv", "docx", "word", "pdf"];
    return check_ext.map((e) => (e == ext ? true : false));
  };
  const getAge = (value) => {
    const currentDate = new Date(value);
    const age = new Date().getFullYear() - currentDate.getFullYear();
    return age < 18;
  };
  return (
    <main id="main">
      <NavigationBarComponent translate={translate} />
      <div className="content">
        <section className="contact">
          <div className="row mt-1 justify-content-center" data-aos="fade-up">
            <div className="col-lg-8" id="error-div">
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <ul id="error-msglist">
                  {Object.entries(errors).map(([key, value]) =>
                    Array.isArray(value) ? (
                      value.map((item, index) =>
                        item != "" ? <li key={index}>{item}</li> : ""
                      )
                    ) : (
                      <li key={key}>{value}</li>
                    )
                  )}
                </ul>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeErrorMsgList}
                ></button>
              </div>
            </div>
            <div className="col-lg-8">
              <form
                action=""
                method="post"
                role="form"
                className="php-email-form"
                id="entry-system-engineer-form"
                encType="multipart/form-data"
              >
                <FullNameComponent
                  labelName={translate("lastName1")}
                  spanName={translate("Kanji")}
                  lastNamePlaceholder={[
                    translate("lastName1"),
                    translate("lastName2"),
                  ]}
                  firstNamePlaceholder={[
                    translate("firstName1"),
                    translate("firstName2"),
                  ]}
                  lastNameValues={[formData.lastName1, formData.lastName2]}
                  firstNameValues={[formData.firstName1, formData.firstName2]}
                  errors={errors}
                  onChange={handleChange}
                  maxLength="30"
                />
                <div className="form-row form-mb">
                  <DOBComponent
                    labelName={translate("Date of Birth")}
                    value={formData.dob}
                    onChange={handleChange}
                  />
                  <EmailComponent
                    labelName="Email "
                    placeholder={translate("email-placeholder")}
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row form-mb">
                  <PhoneComponent
                    labelName={translate("Phone Number")}
                    placeholder={translate("denwabangou")}
                    value={formData.denwabangou}
                    onChange={handleChange}
                    minLength="0"
                    maxLength="20"
                  />
                  <AddressComponent
                    labelName={translate("Address")}
                    value={formData.address}
                    maxLength="200"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row form-mb">
                  <SalaryComponent
                    labelName={translate("Desired Monthly Amount")}
                    salaryValues={[formData.salaryfrom, formData.salaryto]}
                    onChange={handleChange}
                    maxLength="10"
                  />
                </div>
                <div className="form-row form-mb">
                  <ContractualComponent
                    labelName={translate("Desired Contract Form")}
                    value={formData.employeeType}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row form-mb">
                  <OSComponent
                    labelName="OS"
                    values={[
                      formData.OS.Window,
                      formData.OS.Linux,
                      formData.OS.Mac,
                      formData.OS.AIX,
                    ]}
                    onChange={handleOs}
                  />
                </div>
                <div className="form-row form-mb">
                  <LanguageComponent
                    labelName={translate("language")}
                    value={formData.Language}
                    onChange={handleLanguage}
                  />
                </div>
                <div className="form-row form-mb">
                  <DBComponent
                    labelName="DB"
                    db={formData.DB}
                    onChange={handleDB}
                  />
                </div>
                <div className="form-row form-mb">
                  <SkillSheetComponent
                    labelName={translate("Skills Sheet")}
                    value={formData.skillsheet}
                    onChange={handleChange}
                    text={translate("Supported Format")}
                  />
                </div>
                <div className="form-row form-mb">
                  <RemarkComponent
                    labelName={translate("Remarks")}
                    placeholder={translate(
                      "Please enter within 400 characters"
                    )}
                    value={formData.remark}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-row form-mb">
                  <InformationComponent
                    labelName={translate("")}
                    value={formData.chkagree}
                    onChange={handleChange}
                    translate={translate}
                  />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    {translate("contactmailmsgTitle")}
                  </div>
                </div>
                <div className="alert alert-success" id="email-success"></div>
                <div className="form-row form-mb">
                  <div className="form-col1">
                    <div className="text-center">
                      <button
                        onClick={onSubmitForm}
                        className="btn02"
                        id="send_mail"
                      >
                        SEND
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <div id="loading-overlay" className="d-none">
        <div className="animation">
          <div className="i-mail">
            <div className="mail-anim"></div>
          </div>
          <div className="line"></div>
          <div className="i-success">
            <div className="success-anim"></div>
          </div>
        </div>
        <div className="loading-container">
          <div className="loading"></div>
          <div id="loading-text">{translate("sending-mail")}</div>
        </div>
      </div>
    </main>
  );
};

export default SystemEngineerForm;
