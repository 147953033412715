import LabelComponent from "./LabelComponent";

/* eslint-disable react/prop-types */
const SalaryComponent = (props) => {
  return (
    <div className="form-col2">
      <LabelComponent labelName={props.labelName} />
      <div className="form-inputText input-salary ">
        <input
          type="text"
          className="inputType"
          name="salaryfrom"
          id="salaryfrom"
          defaultValue={props.salaryValues[0]}
          onChange={props.onChange}
          required
        />
        <span className="form-sign">～</span>
        <input
          type="text"
          className="inputType"
          name="salaryto"
          id="salaryto"
          defaultValue={props.salaryValues[1]}
          onChange={props.onChange}
          required
        />
        <span className="form-yern">Yen</span>
      </div>
    </div>
  );
};

export default SalaryComponent;
