/* eslint-disable no-unused-vars */
import $ from "jquery";

export function initializePage() {
  $(window).on("load", function () {
    $("#email-success").hide();
    $("#error-div").hide();
    hideLoadingOverlay();
  });
}

function showLoadingOverlay() {
  $("#loading-overlay").removeClass("d-none");
}

function hideLoadingOverlay() {
  $("#loading-overlay").addClass("d-none");
}

export const closeErrorMsgList = () => {
  $("#error-div").hide();
};

export function refreshCSRFToken() {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: "/csrf/refresh",
      type: "GET",
      success: function (response) {
        var newToken = response.token;
        $('meta[name="csrf-token"]').attr("content", newToken);
        resolve();
      },
      error: function (xhr, textStatus, errorThrown) {
        reject(xhr);
      },
    });
  });
}

export function submitForm() {
  refreshCSRFToken()
    .then(function () {
      showLoadingOverlay();
      let formId = $("form").attr("id");
      var form = $(`#${formId}`)[0];

      // Create a new FormData object
      var formData = new FormData(form);
      $.ajax({
        url: $(this).attr("action"),
        type: "POST",
        processData: false,
        contentType: false,
        data: formData,
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },

        success: function (response) {
          if (response.success) {
            $("#email-success").show();
            closeErrorMsgList();
            $("#email-success").text(response.success);
            $(".alert-success").fadeOut(9000);
            $(`#${formId}`)[0].reset();
            hideLoadingOverlay();
            setTimeout(() => {
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }, 9000);
          } else {
            $("#error-msglist").html("");
            $("#error-div").show();
            for (let err of response.error) {
              $("#error-msglist").append($("<li>").text(err));
            }

            $("html, body").animate({ scrollTop: 0 }, "slow");
          }

          hideLoadingOverlay();
        },

        error: function (jqXHR, textStatus, errorThrown) {
          hideLoadingOverlay();
          console.log(textStatus, errorThrown);
        },
      });
    })
    .catch((err) => {
      console.log("Error ", err);
    });
}

// export function submitForm(e) {
//   e.preventDefault();
//   refreshCSRFToken()
//     .then(function () {
//       showLoadingOverlay();
//       let formId = $("form").attr("id");
//       var form = $(`#${formId}`)[0];
//       var formData = new FormData(form);
//       console.log(formData);
//     })
//     .catch((err) => {
//       console.log("Error", err);
//     });
// }

$(document).ready(function () {
  $("form").submit(function (event) {
    event.preventDefault();
    submitForm();
  });
});
