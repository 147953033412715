/* eslint-disable react/prop-types */
import LabelComponent from "./LabelComponent";

const RemarkComponent = (props) => {
  return (
    <div className="form-col1">
      <LabelComponent labelName={props.labelName} />
      <p className="show_message">
        <span className="" id=""></span>
      </p>
      <div className="form-textarea">
        <textarea
          name="remark"
          cols="50"
          rows="5"
          className="inquiry_area"
          id="inquiry_area"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        ></textarea>
      </div>
    </div>
  );
};

export default RemarkComponent;
