import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY>100) {
     setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
        {isVisible && (
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center under_bar">
        <i className="bi bi-arrow-up-short"></i>
      </a>)}
    </div>
  );
};

export default ScrollToTopButton;
