
import React, { useEffect } from 'react';
import $ from 'jquery';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // if you are using bootstrap
import { useLanguage } from "../LanguageContext";
import HeaderBanner from "../common/HeaderBanner";
const InformationSecurity = () => {
 const { translate } = useLanguage();
  useEffect(() => {
    $('.nav li:first').addClass('active');
    $('.tab-content:not(:first)').hide();
    $('.nav li a').click(function (event) {
      event.preventDefault();
      var content = $(this).attr('href');
      $(".nav li a").removeClass("active");
      $(this).parent().addClass('active');
      $(this).parent().siblings().removeClass('active');
      $(content).show();
      $(content).siblings('.tab-content').hide();
      $(".collapse").collapse('hide');
    });
  }, []);

  return (
    <main id="main" className="privacy">
     
      <HeaderBanner
        bannerName={translate("PrivacyPolicyHeader")}
      />
      <section style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <article>
              <div className="col-12">
                <div className="section-title">
                  <h2 className="ttl">
                    <span>{translate("Sec3Ttl1")}</span>
                  </h2>
                </div>
                <div className="letter">
                  <div className="cFix">
                    <p className="letter-lead-txt">
                      {translate("LetterLeadText1")}
                    </p>
                    <ul className="list alpha-list">
                      {[
                        "AlphaLs1-1",
                        "AlphaLs1-2",
                        "AlphaLs1-3",
                        "AlphaLs1-4",
                        "AlphaLs1-5",
                      ].map((item, index) => (
                        <li key={index}>
                          <p className="alpha-ls">
                            <span>{String.fromCharCode(97 + index)}）</span>
                            {translate(item)}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="letter-b-txt">
                      <p>{translate("EncatDate")}</p>
                      <p>{translate("CompanyName")}</p>
                      <p>{translate("DirectorName")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <section className="privacy-bg">
        <div className="container">
          <div className="section-title">
            <h2 className="ttl">
              <span>{translate("Pri02Tt1")}</span>
            </h2>
          </div>
          <div className="private02-lead-txt">
            <p>{translate("CompanyName")}</p>
            <p>{translate("Pri02Tt2")}</p>
            <p>{translate("PriAddress")}</p>
            <p>
              <span className="dis-inline">TEL：03-6661-2879</span>
            </p>
            <p>
              <span className="dis-inline">FAX：03-5207-2956</span>
            </p>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section className="sec-private03">
        <div className="container">
          <div className="section-title">
            <h2 className="ttl">
              <span>{translate("Sec3Ttl1")}</span>
            </h2>
          </div>
          <div>
            <p className="private-lead-txt">{translate("PrivateLeadTxt")}</p>
            <h3 className="pri-ttl pri-ttl1">{translate("PriTtl3")}</h3>
            <div className="sec-table">
              <table>
                <colgroup>
                  <col width="36%" />
                  <col width="34%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  {[
                    ["PriTh1", "PriTh2", "PriTh3"],
                    ["PriTd1-1", "PriTd1-2", "PriTd1-3"],
                    ["PriTd2-1", "PriTd2-2", "PriTd2-3"],
                    ["PriTd3-1", "PriTd3-2", "PriTd3-3"],
                    ["PriTd4-1", "PriTd4-2", "PriTd4-3"],
                    ["PriTd5-1", "PriTd5-2", "PriTd5-3"],
                    ["PriTd6-1", "PriTd6-2", "PriTd6-3"],
                    ["PriTd7-1", "PriTd7-2", "PriTd7-3"],
                  ].map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{translate(cell)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Section 4 */}
      <section className="sec-private04 bg-green-light">
        <div className="container">
          <div>
            <h2 className="pri-ttl pri-ttl2">{translate("PriTtl4")}</h2>
            <div className="ml-30">
              {[1, 2, 3, 4, 5, 6].map((num, index) => (
                <div className="pri-num" key={index}>
                  <span>{num}.</span>
                  {translate(`Sec4PriNum${num}-1`)}
                  {index === 1 ? (
                    <p className="dis-inline">{translate("Sec4PriNum2-2")}</p>
                  ) : null}
                  {index >= 2 && index <= 5 ? (
                    <>
                      <br />
                      {translate(`Sec4PriNum${num}-2`)}
                    </>
                  ) : null}
                  {index === 5 ? (
                    <>
                      <br />
                      {[1, 2, 3, 4, 5, 6].map((subNum, subIndex) => (
                        <p className="pri-num" key={subIndex}>
                          <span>①</span>
                          {translate(`Sec4PriNum6-${subNum * 2 - 1}`)}
                          <br />
                          {translate(`Sec4PriNum6-${subNum * 2}`)}
                        </p>
                      ))}
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Section 5 */}
      <section className="sec-private05">
        <div className="container">
          <div>
            <h2 className="pri-ttl pri-ttl3">{translate("PriTtl5")}</h2>
            <div className="ml-30">
              <p>{translate("Sec5PriNum1-1")}</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default InformationSecurity;
