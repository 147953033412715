/* eslint-disable react/prop-types */
const InformationComponent = (props) => {
  return (
    <div className="form-col1">
      <div className="form-inputCheckbox">
        <div className="form-privacy">
          <h3 className="form-privacy-ttl">
            {props.translate("Handling of personal info")}
          </h3>
          <p>
            {props.translate(
              "We handle personal information entrusted to us as follows."
            )}
          </p>
          <p className="pri-num">
            <span>1.</span>
            {props.translate("About the purpose of use")}
            <br />
            {props.translate("Purpose_personalInfo_used")}
            <br />・{props.translate("Personal_info_used")}
            <br />・{props.translate("Purpose of use")}
          </p>
          <p className="pri-num">
            <span>2.</span>
            {props.translate("About personal")}
            <br />
            {props.translate("Trade Name Data")}
            <br />
            {props.translate("Personal information protection manager")}
            {props.translate("CEOName")} <br />
            {props.translate("Address")}：{props.translate("Location Data1")}
            {props.translate("Location Data2")}
            {props.translate("Location Data3")}
            {props.translate("Location Data4")}
            <br />
            {props.translate("call")}
            ：03-6661-2879
          </p>
          <p className="pri-num">
            <span>3.</span>
            {props.translate("About consignment")}
            <br />
            {props.translate("We may outsource")}
          </p>
          <p className="pri-num">
            <span>4.</span>
            {props.translate("About optional")}
            <br />
            {props.translate("It is optional")}
          </p>
          <p className="pri-num">
            <span>5.</span>
            {props.translate("About complaints")}
            <br />
            {props.translate("About complaints body")}
            <br />
            {props.translate("A fee")}
          </p>
        </div>
        <label htmlFor="chkagree" className="mt-20">
          <input
            type="checkbox"
            name="chkagree"
            id="chkagree"
            defaultValue={props.value}
            onChange={props.onChange}
          />

          <span className="form-checkbox col-red chkagree-text">
            「個人情報の取扱いについて」に同意します。
          </span>
        </label>
      </div>
    </div>
  );
};

export default InformationComponent;
