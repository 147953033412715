import React, { useEffect } from "react";
import AOS from "aos";
import { useLanguage } from "../LanguageContext";
import "../../assets/css/animation.css";
import "../../assets/css/style.css";
import SystemEngineer from "./SystemEngineer.js";
import InfraEngineer from "./InfraEngineer.js";
import OfficeSale from "./OfficeAndSaleRecruit.js";
import HeaderBanner from "../common/HeaderBanner";

const Recruit = ({ recruit }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const { translate } = useLanguage();

  return (
    <main id="main">
      <HeaderBanner bannerName={translate("nav-recruit")} />

      <div className="container" data-aos="fade-up">
        {/* System Engineer Section */}
        <SystemEngineer
          shouldDisplay={recruit === "all" || recruit === "system-engineer"}
        />

        {/* Infra Engineer Section */}
        <InfraEngineer
          shouldDisplay={recruit === "all" || recruit === "infra-engineer"}
        />

        {/* Office & Sale Section */}
        <OfficeSale
          shouldDisplay={recruit === "all" || recruit === "office-sale"}
        />
      </div>
    </main>
  );
};

export default Recruit;
