/* eslint-disable react/prop-types */
import LabelComponent from "./LabelComponent";

const SkillSheetComponent = (props) => {
  return (
    <div className="form-col1">
      <LabelComponent labelName={props.labelName} />
      <div className="form-row form-inputFile">
        <div className="form-col2">
          <input
            type="file"
            className="inputFile"
            name="skillsheet"
            id="skillsheet"
            accept=".xlsx,.xls,.csv,.docx,.word,.pdf"
            multiple
            value={props.value}
            onChange={props.onChange}
            required
          />
        </div>
        <div className="form-col2">
          <p className="file-para">
            ※ {props.text}: 「xlsx、xls、csv、docx、word、pdf」
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkillSheetComponent;
