/* eslint-disable react/prop-types */
const NavigationBarComponent = (props) => {
  return (
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <strong>
            <h2>{props.translate("SystemEngineerEntry")}</h2>
          </strong>
          <ol>
            <li>
              <a href="{{ url('/') }}">{props.translate("nav-home")}</a>
            </li>
            <li>
              <a href="{{ url('/recruit') }}">{props.translate("Recruit")}</a>
            </li>
            <li>{props.translate("SystemEngineerEntry")}</li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default NavigationBarComponent;
