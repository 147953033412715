import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../components/LanguageContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../assets/css/style.css";
import "../assets/vendor/animate.css/animate.min.css";
import startechLogo from "../assets/img/startech.png";
import jpnflt from "../assets/img/jpnflt.png";
import eng_logo from "../assets/img/eng-logo.png";

const Header = () => {
  const location = useLocation();
  const pageName = location.pathname.split("/")[1] || "STARTECH";
const { isJapanese, toggleLanguage, translate } = useLanguage();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <header id="header" className="fixed-top header-transparent">
      <div id="header-top">
        <div className="container-fluid">
          <nav id="navbar" className="navbar navbar-expand-lg">
            <div className="container">
              <h1>
                <Link className="navbar-brand logo d-flex" to="/">
                  <img src={startechLogo} alt="Startech Logo" />
                </Link>
              </h1>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={!isCollapsed}
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
              <div
                // className="collapse navbar-collapse"
                className={`collapse navbar-collapse ${
                  isCollapsed ? "" : "show"
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav d-flex justify-content-end me-auto mb-2 mb-lg-0 w-100 align-items-center">
                  <li className="nav-item" id="listNavTitle">
                    <Link
                      className={`nav-link ${
                        pageName === "STARTECH" ? "active" : ""
                      }`}
                      to="/"
                    >
                      <span>{translate("nav-home")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" id="listNavTitle">
                    <Link
                      className={`nav-link ${
                        pageName === "companyInfo" ? "active" : ""
                      }`}
                      to="/companyInfo"
                    >
                      <span>{translate("nav-companyInfo")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" id="listNavTitle">
                    <Link
                      className={`nav-link ${
                        pageName === "services" ? "active" : ""
                      }`}
                      to="/services"
                    >
                      <span>{translate("nav-services")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" id="listNavTitle">
                    <Link
                      className={`nav-link ${
                        pageName === "recruit" ? "active" : ""
                      }`}
                      to="/recruit"
                    >
                      <span>{translate("nav-recruit")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" id="listNavTitle">
                    <Link
                      className={`nav-link ${
                        pageName === "informationSecurity" ? "active" : ""
                      }`}
                      to="/informationSecurity"
                    >
                      <span>{translate("nav-security")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" id="listNavTitle">
                    <Link
                      className={`nav-link ${
                        pageName === "contact" ? "active" : ""
                      }`}
                      to="/contact"
                    >
                      <span>{translate("nav-contactUs")}</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link ml-3"
                      onClick={() => toggleLanguage("jp")}
                    >
                      <span>
                        <img
                          src={jpnflt}
                          className="flat-jp"
                          alt="Japanese Flag"
                        />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={() => toggleLanguage("en")}
                    
                    >
                     
                      <span>
                        <img
                          src={eng_logo}
                          className="flat-eng"
                          alt="English Flag"
                        />
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
