import React, { useEffect } from "react";
import { Animate } from "../../assets/js/glaxy_animation";

const HeaderBanner = ({ bannerName }) => {
  useEffect(() => {
    Animate();
  },[]);

  return (
    <section id="hero" style={{ padding: "0" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <div id="large-header" className="large-header area">
          <canvas id="demo-canvas"></canvas>
          <h1
            id="glaxy-inner-other"
            className="container textAnimation sequence textDecorate"
          >
            {bannerName}
          </h1>
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HeaderBanner;
