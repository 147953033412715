/* eslint-disable react/prop-types */

import LabelComponent from "./LabelComponent";

/* eslint-disable no-unused-vars */
const FullNameComponent = (props) => {
  return (
    <div className="form-row form-mb">
      <div className="form-col2">
        <LabelComponent labelName={props.labelName} spanName={props.spanName} />
        <div className="form-inputText">
          <input
            type="text"
            className="inputType"
            name="lastName1"
            id="lastName1"
            placeholder={props.lastNamePlaceholder[0]}
            defaultValue={props.lastNameValues[0]}
            onChange={props.onChange}
            required
          />
          <input
            type="text"
            className="inputType"
            name="firstName1"
            id="firstName1"
            placeholder={props.firstNamePlaceholder[0]}
            defaultValue={props.firstNameValues[0]}
            onChange={props.onChange}
            required
          />
        </div>
      </div>
      <div className="form-col2">
        <LabelComponent labelName={props.labelName} spanName={props.spanName} />
        <div className="form-inputText">
          <input
            type="text"
            className="inputType"
            name="lastName2"
            id="lastName2"
            placeholder={props.lastNamePlaceholder[1]}
            defaultValue={props.lastNameValues[1]}
            onChange={props.onChange}
          />
          <input
            type="text"
            className="inputType"
            name="firstName2"
            id="firstName2"
            placeholder={props.firstNamePlaceholder[1]}
            defaultValue={props.firstNameValues[1]}
            onChange={props.onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FullNameComponent;
