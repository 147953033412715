/* eslint-disable react/prop-types */
import cosmediaService3 from "../../../assets/img/appService3.png";
const CloudServiceComponent = (props) => {
  return (
    <>
      <div className="row" data-aos="fade-up">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <strong>
            <h2 className="service-title">
              {props.translate("Service title4")}
            </h2>
          </strong>
          <p>{props.translate("CosmediaServiceBody")}</p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="main-timeline">
            <div className="timeline">
              <div className="timeline-content">
                <div className="timeline-icon">
                  <i className="fa fa-globe"></i>
                </div>
                <h3 className="title">{props.translate("SubServTitle4")}</h3>
                <div className="description" data-aos="zoom-in-left">
                  <img
                    src={cosmediaService3}
                    alt=""
                    className="service-img mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudServiceComponent;
