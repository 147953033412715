import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';

const InfraEntryForm = () => {
    const {translate} = useLanguage();
    const [formData, setFormData] = useState({
        lastName1: '',
        firstName1: '',
        lastName2: '',
        firstName2: '',
        dob: '',
        email: '',
        denwabangou: '',
        address: '',
        salaryfrom: '',
        salaryto: '',
        employeeType: '正社員',
        Os: [],
        InfraTypes: [],
        remark: '',
        chkagree: false,
    });

    const [errors, setErrors] = useState([]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        if (type === 'checkbox') {
            if (name === 'chkagree') {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: checked,
                }));
            } else if (name === 'InfraTypes') {
                let updatedInfraTypes = [...formData.InfraTypes];
    
                if (checked) {
                    if (!updatedInfraTypes.includes(value)) {
                        updatedInfraTypes.push(value);
                    }
                } else {
                    updatedInfraTypes = updatedInfraTypes.filter((infraType) => infraType !== value);
                }

                setFormData(prevState => ({
                    ...prevState,
                    InfraTypes: updatedInfraTypes
                }));
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: checked
                        ? [...prevState[name], value]
                        : prevState[name].filter((item) => item !== value),
                }));
            }
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };
    
    const handleFormSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateFormData(formData);
        setErrors(validationErrors);

        if (validationErrors.length === 0) {
            console.log('Form Data:', formData);

            resetForm();
        } else {
            window.scrollTo(0, 0);
        }
    };

    const resetForm = () => {
        setFormData({
            lastName1: '',
            firstName1: '',
            lastName2: '',
            firstName2: '',
            dob: '',
            email: '',
            denwabangou: '',
            address: '',
            salaryfrom: '',
            salaryto: '',
            employeeType: '正社員',
            Os: [],
            InfraTypes: [],
            remark: '',
            chkagree: false,
        });
        setErrors([]);
    };
    

    const validateFormData = (data) => {
        let errors = [];

        // Validate last name and first name fields
        if (!data.lastName1) {
            errors.push({ message: translate("lastName1-required") });
        }
        if (data.lastName1.length > 20) {
            errors.push({ message: translate("lastName1-max") });
        }
        if (!data.firstName1) {
            errors.push({ message: translate("firstName1-required") });
        }
        if (data.firstName1.length > 20) {
            errors.push({ message: translate("firstName1-max") });
        }
        if (!data.lastName2) {
            errors.push({ message: translate("lastName2-required") });
        }
        if (data.lastName2.length > 20) {
            errors.push({ message: translate("lastName2-max") });
        }
        if (!data.firstName2) {
            errors.push({ message: translate("firstName2-required") });
        }
        if (data.firstName2.length > 20) {
            errors.push({ message: translate("firstName2-max") });
        }

        // Validate email
        if (!data.email) {
            errors.push({ message: translate("email-required") });
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(data.email)) {
                errors.push({ message: translate("email-email") });
            }
        }

        // Validate date of birth
        if (!data.dob) {
            errors.push({ message: translate("dob-required") });
        } else {
            const dobRegex = /^(0[1-9]|1[0-2]) (0[1-9]|[12][0-9]|3[01]) \d{2}$/;
            if (!dobRegex.test(data.dob)) {
                errors.push({ message: translate("dob-type") });
            }
            const dobParts = data.dob.split(" ");
            const enteredDate = new Date(`20${dobParts[2]}`, dobParts[0] - 1, dobParts[1]);
            const minAgeDate = new Date();
            minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);
            if (enteredDate > minAgeDate) {
                errors.push({ message: translate("dob-before20") });
            }
        }

        // Validate phone number
        if (!data.denwabangou) {
            errors.push({ message: translate("denwabangou-required") });
        } else {
            const phoneRegex = /^\d+$/;
            if (!phoneRegex.test(data.denwabangou)) {
                errors.push({ message: translate("denwabangou-numeric") });
            }
            if (data.denwabangou.length > 20) {
                errors.push({ message: translate("denwabangou-length") });
            }
        }

        // Validate address
        if (!data.address) {
            errors.push({ message: translate("address-required") });
        } else if (data.address.length > 50) {
            errors.push({ message: translate("address-max") });
        }
        // Validate salary from
        if (!data.salaryfrom && !(/^\d+$/.test(data.salaryfrom))) {
            errors.push({ message: translate("salaryfrom-numeric") });
        }

        // Validate salary to
        if (!data.salaryto && !(/^\d+$/.test(data.salaryto))) {
            errors.push({ message: translate("salaryto-numeric") });
        }


        // Validate skillsheet
        if (!data.skillsheet) {
            errors.push({ message: translate("skillsheet-required") });
        } else if (data.skillsheet.length > 100) {
            errors.push({ message: translate("skillsheet-max") });
        }

        // Validate OS
        if (!data.Os || data.Os.length === 0) {
            errors.push({ message: translate("os-experience") });
        }
        // Validate InfraTypes
        if (!data.InfraTypes || data.InfraTypes.length === 0) {
            errors.push({ message: translate("InfraTypes-experience") });
        }

        // Validate remark
        if (!data.remark) {
            errors.push({ message: translate("remark-required") });
        } else if (data.remark.length < 0 || data.remark.length > 400) {
            errors.push({ message: translate("remark-max") });
        }

        // Validate agreement checkbox
        if (!data.chkagree) {
            errors.push({ message: translate("chkagree-required") });
        }

        return errors;
    };

    return (
        <main id="main">
            {/* Breadcrumbs section */}
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <strong><h2>{translate("InfraEntry")}</h2></strong>
                        <ol>
                            <li>
                                <a href="/">{translate("nav-home")}</a>
                            </li>
                            <li>
                                <a href="/recruit">{translate("Recruit")}</a>
                            </li>
                            <li>{translate("InfraEntry")}</li>
                        </ol>
                    </div>
                </div>
            </section>

            {/* Form section */}
            <div className="content">
                <section className="contact">
                    <div className="row mt-1 justify-content-center" data-aos="fade-up">
                        <div className="col-lg-8" id="error-div">
                            {errors.length > 0 && (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <ul id="error-msglist">
                                        {errors.map((error, index) => (
                                            <li key={index}>{error.message}</li>
                                        ))}
                                    </ul>
                                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setErrors([])}></button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="entry_form col-lg-8">
                        <form
                            onSubmit={handleFormSubmit}
                            role="form"
                            className="php-email-form"
                            id="entry-system-engineer-form"
                            encType="multipart/form-data"
                        >
                            {/* CSRF token */}
                            <input type="hidden" name="_token" value="{{ csrf_token() }}" />

                            {/* Full Name (Kanji and Katakana) */}
                            <div className="form-row form-mb">
                                <div className="form-col2">
                                    <label htmlFor="lastName1" className="form-label">
                                        {translate("Full Name")}<span className="require">*</span> {translate("Kanji")}
                                    </label>
                                    <div className="form-inputText">
                                        <input type="text" className="inputType" name="lastName1" id="lastName1" placeholder={translate("lastName1")} value={formData.lastName1} onChange={handleInputChange} />
                                        <input type="text" className="inputType" name="firstName1" placeholder={translate("firstName1")} value={formData.firstName1} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="form-col2">
                                    <label htmlFor="lastName2" className="form-label">
                                        {translate("Full Name")}<span className="require">*</span>{translate("Katakana")}
                                    </label>
                                    <div className="form-inputText">
                                        <input type="text" className="inputType" name="lastName2" id="lastName2" placeholder={translate("lastName2")} value={formData.lastName2} onChange={handleInputChange} />
                                        <input type="text" className="inputType" name="firstName2" placeholder={translate("firstName2")} value={formData.firstName2} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Date of Birth and Email */}
                            <div className="form-row form-mb">
                                <div className="form-col2">
                                    <label htmlFor="dob" className="form-label">
                                        {translate("Date of Birth")} <span className="require">*</span>
                                    </label>
                                    <div className="form-inputText">
                                        <input type="date" className="inputType" name="dob" id="dob" value={formData.dob} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="form-col2">
                                    <label htmlFor="email" className="form-label">
                                        {translate("Your Email")} <span className="require">*</span>
                                    </label>
                                    <div className="form-inputText">
                                        <input type="text" className="inputType" name="email" id="email" placeholder={translate("email-placeholder")} value={formData.email} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Phone Number and Address */}
                            <div className="form-row form-mb">
                                <div className="form-col2">
                                    <label htmlFor="denwabangou" className="form-label">
                                        {translate("Phone Number")} <span className="require">*</span>
                                    </label>
                                    <div className="form-inputText">
                                        <input type="text" className="inputType" name="denwabangou" id="denwabangou" placeholder={translate("denwabangou")} value={formData.denwabangou} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="form-col2">
                                    <label htmlFor="address" className="form-label">
                                        {translate("Address")}  <span className="require">*</span>
                                    </label>
                                    <div className="form-inputText">
                                        <input type="text" className="inputType" name="address" id="address" value={formData.address} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Desired Monthly Amount */}
                            <div className="form-row form-mb">
                                <div className="form-col2">
                                    <label htmlFor="salaryfrom" className="form-label">
                                        {translate("Desired Monthly Amount")} <span className="require">*</span>
                                    </label>
                                    <div className="form-inputText input-salary">
                                        <input type="text" className="inputType" name="salaryfrom" id="salaryfrom" value={formData.salaryfrom} onChange={handleInputChange} />
                                        <span className="form-sign">～</span>
                                        <input type="text" className="inputType" name="salaryto" id="salaryto" value={formData.salaryto} onChange={handleInputChange} />
                                        <span className="form-yern">{translate("Yen")} </span>
                                    </div>
                                </div>
                            </div>

                            {/* Desired Contract Form (Radio buttons) */}
                            <div className="form-row form-mb">
                                <div className="form-col1">
                                    <label className="form-label">
                                        {translate("Desired Contract Form")}<span className="require">*</span>
                                    </label>
                                    <div className="form-row2 form-inputRadio">
                                        <div className="form-col6">
                                            <label htmlFor="seyi">
                                                <input type="radio" value="正社員" name="employeeType" id="seyi" checked={formData.employeeType === '正社員'} onChange={handleInputChange} />
                                                <span className="form-radio-text">{translate("Permanent")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col6">
                                            <label htmlFor="keyiyaku">
                                                <input type="radio" value="契約社員" name="employeeType" id="keyiyaku" checked={formData.employeeType === '契約社員'} onChange={handleInputChange} />
                                                <span className="form-radio-text">{translate("Contract")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col6">
                                            <label htmlFor="haken">
                                                <input type="radio" value="派遣社員" name="employeeType" id="haken" checked={formData.employeeType === '派遣社員'} onChange={handleInputChange} />
                                                <span className="form-radio-text">{translate("Temporary")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col6">
                                            <label htmlFor="gyoumu">
                                                <input type="radio" value="業務委託" name="employeeType" id="gyoumu" checked={formData.employeeType === '業務委託'} onChange={handleInputChange} />
                                                <span className="form-radio-text">{translate("Subcontracting")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* OS Checkbox */}
                            <div className="form-row form-mb mt-3">
                                <div className="form-col1 mt-3 file-para mt-3">
                                    <label className="form-label">OS <span className="require">*</span></label>
                                    <div className="form-row2 form-inputCheckbox">
                                        <div className="form-col6">
                                            <label htmlFor="Window">
                                                <input type="checkbox" value="Window" name="Os" id="Window" checked={formData.Os.includes('Window')} onChange={handleInputChange} />
                                                <span className="form-checkbox">Windows</span>
                                            </label>
                                        </div>
                                        <div className="form-col6">
                                            <label htmlFor="Linux">
                                                <input type="checkbox" value="Linux" name="Os" id="Linux" checked={formData.Os.includes('Linux')} onChange={handleInputChange} />
                                                <span className="form-checkbox">Linux</span>
                                            </label>
                                        </div>
                                        <div className="form-col6">
                                            <label htmlFor="Mac">
                                                <input type="checkbox" value="Mac" name="Os" id="Mac" checked={formData.Os.includes('Mac')} onChange={handleInputChange} />
                                                <span className="form-checkbox">Mac</span>
                                            </label>
                                        </div>
                                        <div className="form-col6">
                                            <label htmlFor="AIX">
                                                <input type="checkbox" value="AIX" name="Os" id="AIX" checked={formData.Os.includes('AIX')} onChange={handleInputChange} />
                                                <span className="form-checkbox">AIX</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Infra Type Checkbox */}
                            <div className="form-row form-mb mt-3">
                                <div className="form-col1 mt-3">
                                    <label className="form-label">{translate("Infratype")} <span className="require">*</span></label>
                                    <div className="form-row2 form-inputCheckbox">
                                        <div className="form-col4">
                                            <label htmlFor="serverEngineer">
                                                <input type="checkbox" value="serverEngineer" name="InfraTypes" id="serverEngineer" checked={formData.InfraTypes.includes('serverEngineer')} onChange={handleInputChange} />
                                                <span className="form-checkbox">{translate("Infratype1")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col4">
                                            <label htmlFor="storageEngineer">
                                                <input type="checkbox" value="storageEngineer" name="InfraTypes" id="storageEngineer" checked={formData.InfraTypes.includes('storageEngineer')} onChange={handleInputChange} />
                                                <span className="form-checkbox">{translate("Infratype2")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col4">
                                            <label htmlFor="netWorkEngineer">
                                                <input type="checkbox" value="netWorkEngineer" name="InfraTypes" id="netWorkEngineer" checked={formData.InfraTypes.includes('netWorkEngineer')} onChange={handleInputChange} />
                                                <span className="form-checkbox">{translate("Infratype3")}</span>
                                            </label>
                                        </div>
                                        <div className="form-col4">
                                            <label htmlFor="databaseEngineer">
                                                <input type="checkbox" value="databaseEngineer" name="InfraTypes" id="databaseEngineer" checked={formData.InfraTypes.includes('databaseEngineer')} onChange={handleInputChange} />
                                                <span className="form-checkbox">{translate("Infratype4")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Skills Sheet File Input */}
                            <div className="form-row form-mb mt-4">
                                <div className="form-col1 mt-2">
                                    <label htmlFor="skillsheet" className="form-label">
                                        {translate("Skills Sheet")} <span className="require">*</span>
                                    </label>
                                    <div className="form-row form-inputFile">
                                        <div className="form-col2">
                                            <input type="file" className="inputFile" name="skillsheet" id="skillsheet" accept=".xlsx,.xls,.csv,.docx,.word,.pdf" multiple onChange={handleInputChange} />
                                        </div>
                                        <div className="form-col2">
                                            <p className="file-para">
                                                ※{translate("Supported Format")}：「xlsx、xls、csv、docx、word、pdf」
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Remarks Textarea */}
                            <div className="form-row form-mb">
                                <div className="form-col1">
                                    <label htmlFor="remark" className="form-label">
                                        {translate("Remarks")}<span className="require">*</span>
                                    </label>
                                    <div className="form-textarea">
                                        <textarea name="remark" cols="50" rows="5" className="inquiry_area" id="remark" placeholder={translate("Please enter within 400 characters")} value={formData.remark} onChange={handleInputChange}></textarea>
                                    </div>
                                </div>
                            </div>

                            {/* Privacy Policy Checkbox */}
                            <div className="form-row form-mb">
                                <div className="form-col1">
                                    <div className="form-inputCheckbox">
                                        <div className="form-privacy">
                                            <h3 className="form-privacy-ttl">
                                                {translate("Handling of personal info")}
                                            </h3>
                                            <p>
                                                {translate("We handle personal information entrusted to us as follows.")}
                                            </p>
                                            <p className="pri-num">
                                                <span>1.</span> {translate("About the purpose of use")}<br />
                                                {translate("Purpose_personalInfo_used")}<br />
                                                {translate("Personal_info_used")}<br />
                                                {translate("Purpose of use")}<br />
                                            </p>
                                            <p className="pri-num">
                                                <span>2.</span> {translate("About personal")}<br />
                                                {translate("Trade Name Data")}<br />
                                                {translate("Personal information protection manager")}
                                                {translate("CEOName")}
                                                {translate("Address")}
                                                {translate("Location Data1")}
                                                {translate("Location Data2")}
                                                {translate("Location Data3")}
                                                {translate("Location Data4")}<br />
                                                {translate("call")}：03-6661-2879
                                            </p>
                                            <p className="pri-num">
                                                <span>3.</span>{translate("About consignment")}<br />
                                                {translate("We may outsource")}
                                            </p>
                                            <p className="pri-num">
                                                <span>4.</span> {translate("About optional")}<br />
                                                {translate("It is optional")}
                                            </p>
                                            <p className="pri-num">
                                                <span>5.</span>{translate("About complaints")}<br />
                                                {translate("About complaints body")}
                                                {translate("A fee")}
                                            </p>
                                        </div>
                                    </div>

                                    <label htmlFor="chkagree" className="mt-20">
                                        <input type="checkbox" name="chkagree" id="chkagree" checked={formData.chkagree} onChange={handleInputChange} />
                                        <span className="form-checkbox col-red chkagree-text">
                                            {translate("Handling of personal information")}
                                        </span>
                                    </label>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">
                                    Your message has been sent. Thank you!
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col1">
                                    <div className="text-center">
                                        <button type="submit" className="btn02" id="send_mail">
                                            {translate("Send")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
            {/* Loading overlay */}
            <div id="loading-overlay" className="d-none">
                <div className='animation'>
                    <div className='i-mail'>
                        <div className='mail-anim'></div>
                    </div>
                    <div className='line'></div>
                    <div className='i-success'>
                        <div className='success-anim'></div>
                    </div>
                </div>
                <div className="loading-container">
                    <div className="loading"></div>
                    <div id="loading-text">{translate("sending-mail")}</div>
                </div>
            </div>
        </main>
    );
};

export default InfraEntryForm;
