  import React, { useEffect, useState } from 'react';
  import axios from 'axios';
import HeaderBanner from '../common/HeaderBanner';
import { useLanguage } from '../LanguageContext';
  
  const Contact = () => {
    const { translate } = useLanguage();
      const [formData, setFormData] = useState({
          companyname: '',
          yourname: '',
          email: '',
          phone: '',
          postCode: '',
          address: '',
          subject: '',
          message: '',
          chkagree: false,
      });
      const [errors, setErrors] = useState([]);
      const [success, setSuccess] = useState('');
      const [loading, setLoading] = useState(false);
      const [sentMessage, setSentMessage] = useState('');
  
      useEffect(() => {
          if (success) {
              setTimeout(() => setSuccess(''), 7000);
              window.scrollTo(0, 500);
          }
      }, [success]);
  
      const handleChange = (e) => {
          const { name, value, type, checked } = e.target;
          console.log(name + "" + value);
          setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: type === 'checkbox' ? checked : value,
          }));
      };
  
      const handleSubmit = async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
              const response = await axios.post('/contact/store', formData);
              setSentMessage(response.data.message);
              setSuccess('Message sent successfully!');
          } catch (error) {
              if (error.response) {
                  setErrors(error.response.data.errors);
              }
          } finally {
              setLoading(false);
          }
      };
  

   return (
        <main id="main">
            <HeaderBanner bannerName={translate("nav-contactUs")}/>
            <section id="contact" className="contact">
                <div className="container">
                    <div className="row add-width">
                        <div className="d-flex col-lg-6 mt-2 map-section clearfix-padding map-design">
                            <iframe
                                className="w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.2366691228362!2d139.7758593156025!3d35.67117408019673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188967353f2a0d%3A0xdeb14bf123f9a4!2z44CSMTA0LTAwNDMgVG9reW8sIENodW8gQ2l0eSwgTWluYXRvLCAyLWNoxY1tZeKIkjTiiJIxIOadseS6rOOCouODkeODrOODq-ODiOODnuODg-OCr-ODk-ODqw!5e0!3m2!1sen!2sjp!4v1680583373037!5m2!1sen!2sjp"
                                frameBorder="0"
                                allowFullScreen
                                title="Google Map"
                            ></iframe>
                        </div>

                        <div className="col-lg-6 ml-5 mt-2" data-aos="fade-up">
                            {errors.length > 0 && (
                                <div className="w-full">
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="alert"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                </div>
                            )}

                            <div className="w-full">
                                <form onSubmit={handleSubmit} className="php-email-form">
                                    <div className="row">
                                        <div className="col-md-6 form-group form-floating">
                                            <input
                                                type="text"
                                                name="companyname"
                                                className={`custom-input form-control ${errors.companyname ? 'is-invalid' : ''}`}
                                                id="companyname"
                                                placeholder="Company Name"
                                                value={formData.companyname}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className="floating-label" htmlFor="companyname">
                                                Company Name<span className="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0 form-floating">
                                            <input
                                                type="text"
                                                className={`custom-input form-control ${errors.yourname ? 'is-invalid' : ''}`}
                                                name="yourname"
                                                id="yourname"
                                                placeholder="Your Name"
                                                value={formData.yourname}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className="floating-label" htmlFor="yourname">
                                                Your Name<span className="text-danger">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 form-group form-floating">
                                            <input
                                                type="text"
                                                name="email"
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                placeholder="Your Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className="floating-label" htmlFor="email">
                                                Your Email<span className="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0 form-floating">
                                            <input
                                                type="phone"
                                                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                                name="phone"
                                                id="phone"
                                                placeholder="Your Phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className="floating-label" htmlFor="phone">
                                                Your Phone<span className="text-danger">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 form-group form-floating">
                                            <input
                                                type="text"
                                                name="postCode"
                                                className="form-control"
                                                id="postCode"
                                                placeholder="Post Code"
                                                value={formData.postCode}
                                                onChange={handleChange}
                                            />
                                            <label className="floating-label" htmlFor="postCode">
                                                Post Code
                                            </label>
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0 form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address"
                                                id="address"
                                                placeholder="Address"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                            <label className="floating-label" htmlFor="address">
                                                Address
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            id="subject"
                                            placeholder="Subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label className="floating-label" htmlFor="subject">
                                            Subject<span className="text-danger">*</span>
                                        </label>
                                    </div>

                                    <div className="form-group form-floating">
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            id="message"
                                            rows="5"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        <label className="floating-label" htmlFor="message">
                                            Message<span className="text-danger">*</span>
                                        </label>
                                    </div>

                                    <div className="form-row form-mb">
                                        <div className="form-col1">
                                            <div className="form-inputCheckbox">
                                                <div className="form-privacy">
                                                    <h3 className="form-privacy-ttl">Handling of personal info</h3>
                                                    <p>We handle personal information entrusted to us as follows.</p>
                                                    <p className="pri-num">
                                                        <span>1.</span>
                                                        About the purpose of use
                                                        <br />
                                                        Purpose_personalInfo_used
                                                        <br />
                                                        ・Personal_info_used_contact
                                                        <br />
                                                        ・Purpose of use_contact
                                                    </p>
                                                    <p className="pri-num">
                                                        <span>2.</span>
                                                        About personal
                                                        <br />
                                                        Prupose of About personal
                                                        <br />
                                                        Trade Name Data
                                                        <br />
                                                        Personal information protection manager CEOName
                                                        <br />
                                                        Address： Location Data1 Location Data2 Location Data3 Location
                                                        Data4
                                                        <br />
                                                        call ：03-6661-2879
                                                    </p>
                                                    <p className="pri-num">
                                                        <span>3.</span>
                                                        About consignment
                                                        <br />
                                                        We may outsource
                                                    </p>
                                                    <p className="pri-num">
                                                        <span>4.</span>
                                                       Here's the rest of the `Contact.js` React component, ensuring the `return` statement is correctly placed within the function:

```jsx</p>
                                                    <p className="pri-num">
                                                        <span>4.</span>
                                                        It is optional to provide personal information, but it may be necessary for certain services.
                                                    </p>
                                                    <p className="pri-num">
                                                        <span>5.</span>
                                                        About complaints
                                                        <br />
                                                        Complaints body
                                                        <br />
                                                        A fee may apply
                                                    </p>
                                                </div>
                                                <label htmlFor="chkagree" className="mt-20">
                                                    <input
                                                        type="checkbox"
                                                        name="chkagree"
                                                        id="chkagree"
                                                        value="chk"
                                                        checked={formData.chkagree}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="form-checkbox col-red chkagree-text">
                                                        Handling of personal information
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-3">
                                        {loading && <div className="loading">Loading</div>}
                                        {errors.message && <div className="error-message">{errors.message}</div>}
                                        {sentMessage && <div className="sent-message">{sentMessage}</div>}
                                    </div>

                                    {success && <div className="alert alert-success">{success}</div>}

                                    <div className="text-center">
                                        <button type="button">Send Message</button>/
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );

};

export default Contact;
