import React from "react";
import { useLanguage } from "../LanguageContext";

const History = () => {
  const { translate } = useLanguage();
  return (
    <div className="yange-section">
      <div className="section-title">
        <h2 className="yange-title" style={{ textAlign: "center" }}>
          {translate("cmpHistoryTitle")}
        </h2>
      </div>
      <div className="yange-block">
        <ul className="matchHeightList" data-aos="fade-up">
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate10")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData10")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate9")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData9")}
            </span>
          </li>
          <li>
            <span
              className="listItem yangeDate"
              id="newCompanyInfo"
              data-aos="zoom-in-up"
              style={{
                padding: "30px",
                textAlign: "center",
                alignItems: "center !important",
              }}
            >
              {translate("cmpHistoryDDate8")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData8")}
              <br />
              {translate("cmpHistoryData8-1")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate1")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData1")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate2")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData2")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate3")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData3")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate4")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData4")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate5")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData5")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate6")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData6")}
            </span>
          </li>
          <li>
            <span className="listItem yangeDate" data-aos="zoom-in-up">
              {translate("cmpHistoryDDate7")}
            </span>
            <span className="listItem yangeTtl" data-aos="zoom-in-up">
              {translate("cmpHistoryData7")}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default History;
