import LabelComponent from "./LabelComponent";

/* eslint-disable react/prop-types */
const EmailComponent = (props) => {
  return (
    <div className="form-col2">
      <LabelComponent labelName={props.labelName} />
      <div className="form-inputText">
        <input
          type="text"
          className="inputType"
          name="email"
          id="email"
          placeholder={props.placeholder}
          defaultValue={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export default EmailComponent;
