/* eslint-disable react/prop-types */
import LabelComponent from "./LabelComponent";

const LanguageComponent = (props) => {
  return (
    <div className="form-col1">
      <LabelComponent labelName={props.labelName} />
      <div className="form-row2 form-inputCheckbox">
        <div className="form-col6">
          <label htmlFor="Java">
            <input
              type="checkbox"
              value="Java"
              name="gengo[]"
              id="Java"
              defaultChecked={props.value.Java}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Java</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="PHP">
            <input
              type="checkbox"
              value="PHP"
              name="gengo[]"
              id="PHP"
              defaultChecked={props.value.PHP}
              onChange={props.onChange}
            />
            <span className="form-checkbox">PHP</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Phython">
            <input
              type="checkbox"
              value="Python"
              name="gengo[]"
              id="Phython"
              defaultChecked={props.value.Python}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Python</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="C/C#/C++">
            <input
              type="checkbox"
              value="C/C#/C++"
              name="gengo[]"
              id="C/C#/C++"
              defaultChecked={props.value.C}
              onChange={props.onChange}
            />
            <span className="form-checkbox">C/C#/C++</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Ruby">
            <input
              type="checkbox"
              value="Ruby"
              name="gengo[]"
              id="Ruby"
              defaultChecked={props.value.Ruby}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Ruby</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor=".NET">
            <input
              type="checkbox"
              value=".NET"
              name="gengo[]"
              id=".NET"
              defaultChecked={props.value.Net}
              onChange={props.onChange}
            />
            <span className="form-checkbox">.NET</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Javascript">
            <input
              type="checkbox"
              value="Javascript"
              name="gengo[]"
              id="Javascript"
              defaultChecked={props.value.Javascript}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Javascript</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="VisualBasic">
            <input
              type="checkbox"
              value="VisualBasic"
              name="gengo[]"
              id="VisualBasic"
              defaultChecked={props.value.VisualBasic}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Visual Basic</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="Android">
            <input
              type="checkbox"
              value="Android"
              name="gengo[]"
              id="Android"
              defaultChecked={props.value.Android}
              onChange={props.onChange}
            />
            <span className="form-checkbox">Android</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="SalesForce">
            <input
              type="checkbox"
              value="SalesForce"
              name="gengo[]"
              id="SalesForce"
              defaultChecked={props.value.SalesForce}
              onChange={props.onChange}
            />
            <span className="form-checkbox">SalesForce</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="IOS">
            <input
              type="checkbox"
              value="IOS"
              name="gengo[]"
              id="IOS"
              defaultChecked={props.value.IOS}
              onChange={props.onChange}
            />
            <span className="form-checkbox">IOS</span>
          </label>
        </div>
        <div className="form-col6">
          <label htmlFor="otherLanguage">
            <input
              type="checkbox"
              value="otherLanguage"
              name="gengo[]"
              id="otherLanguage"
              defaultChecked={props.value.Other}
              onChange={props.onChange}
            />
            <span className="form-checkbox">その他</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default LanguageComponent;
