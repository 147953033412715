import React from "react";
import { useLanguage } from "../LanguageContext";

const About = () => {
  const { translate } = useLanguage();

  return (
    <div className="m-7 aos-animate">
      <article className="entry" data-aos="fade-up">
        <div className="companyBlock cFix">
          <ul id="cmpInfoUL">
            <li className="company-title" data-aos="fade-up">
              <span>{translate("Trade Name")}</span>
            </li>

            <li data-aos="fade-up">
              <span className="company-Data">
                {translate("Trade Name Data")}
              </span>
            </li>

            <li className="company-title" data-aos="fade-up">
              <span>{translate("Location")}</span>
            </li>

            <li data-aos="fade-up">
              <span className="company-Data">
                {translate("Location Data1")} <br />
                {translate("Location Data2")}
                {translate("Location Data3")}
                {translate("Location Data4")}
              </span>
            </li>

            <li className="company-title" data-aos="fade-up">
              <span>{translate("Establishment")}</span>
            </li>

            <li data-aos="fade-up">
              <span className="company-Data">
                {translate("Establish Data")}
              </span>
            </li>

            <li className="company-title" data-aos="fade-up">
              <span>{translate("Capital")}</span>
            </li>
            <li data-aos="fade-up">
              <span className="company-Data">{translate("Capital Data")}</span>
            </li>
            <li className="company-title" data-aos="fade-up">
              <span>{translate("President")}</span>
            </li>
            <li data-aos="fade-up">
              <span className="company-Data">
                {translate("President Data1")}
              </span>
            </li>
            <li className="company-title" data-aos="fade-up">
              <span>{translate("Main Bank")}</span>
            </li>
            <li data-aos="fade-up">
              <span className="company-Data">
                {translate("Main Bank Data1")}
                <br />
                {translate("Main Bank Data2")}
              </span>
            </li>
            <li className="company-title" data-aos="fade-up">
              <span>{translate("Approval and Notification Acceptance")}</span>
            </li>
            <li data-aos="fade-up">
              <span className="company-Data">
                {translate("Approval Data1")}
                <br />
                {translate("Approval Data2")}
                <br />
                {translate("Approval Data5")}
              </span>
            </li>
          </ul>
        </div>
      </article>
    </div>
  );
};

export default About;
