import React, { useEffect } from "react";
import HeaderBanner from "../common/HeaderBanner";
import About from "./About";
import History from "./History";
import MarginRate from "./MarginRate";
import { useLanguage } from "../LanguageContext";

const CompanyInfo = () => {
  useEffect(() => {});
  const { translate } = useLanguage();
  return (
    <main id="main">
      <HeaderBanner bannerName={translate("nav-companyInfo")} />
      <section
        id="company"
        className="services sectoin-bg"
        style={{ backgroundColor: "white" }}
      >
        <div className="container" data-aos="fade-up">
          <About />
          {/* History */}
          <History />
          {/* MARGIN RATE BASED ON LABOR DISPATCH LAW */}
          <MarginRate />
        </div>
      </section>
    </main>
  );
};
export default CompanyInfo;
