import React from "react";
import { Helmet } from "react-helmet";
import "../assets/css/style.css";
import icon from "../assets/img/startech-favico.png";
const HeadContent = ({ pageTitle }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href={icon} />
      <title>{pageTitle}</title>
      <meta
        name="keywords"
        content="株式会社 STAR TECH, STAR TECH IT COMPANY,STAR TECH IT COMPANY JAPAN,STAR IT企業、Japan Myanmar IT Cooperation, Japan Myanmar IT Solution,STAR日本ミャンマーIT会社、IT Solution, スター日本ミャンマーIT Solution,日本 IT STAR、STAR、IT STAR TECH, STARTECH株式会社,startech株式会社star tech,star,tech, Startech,StarTech, star tech japan, startech japan,株式会社スターテック,スター,スターテック、STAR TECH株式会社"
      />
      <meta
        name="description"
        content="弊社は,ソフトウェア開発からインフラ設計構築,システム運用支援までトータルなソリューションを提供する“ITソリューション”の拡充に加え, IT業界の人材不足の課題対応策として“社員教育”の強化に取り組んでおります。"
      />
      <meta name="csrf-token" content="{{ csrf_token() }}" />

      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
        integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
        crossOrigin="anonymous"
      ></script>

      <link href="{{ asset('assets/img/startech-favico.png') }}" rel="icon" />
      <link
        href="{{ asset('assets/img/apple-touch-icon.png') }}"
        rel="apple-touch-icon"
      />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,700;1,900&display=swap"
        rel="stylesheet"
      />

      <link
        href="https://fonts.googleapis.com/css?family=Raleway:200,400,800"
        rel="stylesheet"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"
      />

      <link
        href="{{ asset('../assets/vendor/animate.css/animate.min.css') }}"
        rel="stylesheet"
      />
      <link
        href="{{ asset('../assets/vendor/aos/aos.css') }}"
        rel="stylesheet"
      />
      <link
        href="{{ asset('../assets/vendor/bootstrap/css/bootstrap.min.css') }}"
        rel="stylesheet"
      />
      <link
        href="{{ asset('../assets/vendor/bootstrap-icons/bootstrap-icons.css') }}"
        rel="stylesheet"
      />
      <link
        href="{{ asset('../assets/vendor/boxicons/css/boxicons.min.css') }}"
        rel="stylesheet"
      />
      <link
        href="{{ asset('../assets/vendor/glightbox/css/glightbox.min.css') }}"
        rel="stylesheet"
      />
      <link
        href="{{ asset('../assets/vendor/swiper/swiper-bundle.min.css') }}"
        rel="stylesheet"
      />

      <link href="{{ asset('../assets/css/style.css') }}" rel="stylesheet" />
      <link
        href="{{ asset('../assets/css/animation.css') }}"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default HeadContent;
