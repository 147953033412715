import React, { useEffect } from "react";
import { useLanguage } from "../LanguageContext";
import AOS from "aos";
import "../../assets/css/style.css";

const InfraEngineer = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const { translate } = useLanguage();

    return (
        <section className="services" style={{ backgroundColor: 'white' }}>
        <div className="container">
            <div className="section-title">
                <h2 className="recruit-title" style={{ textAlign: 'center' }}>
                    {translate('Infrastructure Engineer')}
                </h2>
            </div>
            <div className="row mt-1 aos-init aos-animate" style={{ maxWidth: '98%', margin: '0 auto' }}>
            <article className="entry entry-boxshadow" style={{ marginBottom: '30px' }}>
                <div className="companyBlock cFix">
                <ul>
                    <li className="company-title">
                    <span>{translate('Job Type')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Job Type Data2')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Job Description')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Job Description Data2')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Job Vacancy')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Job Vacancy Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Recruitment department')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Recruitment department Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Basic Salary')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Basic Salary Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Allowance')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Allowance Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Bonus')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Bonus Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Work Location')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Work Location Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Working Hours')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Working Hours Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Welfare')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Welfare Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Education System')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Education System Data2')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Holiday / Vacation')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Holiday / Vacation Data')}</p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Interview')}</span>
                    </li>
                    <li>
                    <p className="company-Data">
                        {translate('Interview Data1')}
                        <br />
                        {translate('Interview Data2')}
                    </p>
                    </li>

                    <li className="company-title">
                    <span>{translate('Documents')}</span>
                    </li>
                    <li>
                    <p className="company-Data">{translate('Documents Data1')}</p>
                    </li>
                </ul>
                </div>
                <div className="text-center" id="recuritSE" style={{ marginTop: '45px' }}>
                <a href="/InfraEntryForm" className="btn02">
                    {translate('Entry')}
                </a>
                </div>
            </article>
            </div>
        </div>
        </section>
    );
};

export default InfraEngineer;
