import React from "react";
import "../../assets/css/style.css";
import VideoSection from "./VideoSection";
import ServiceSection from "./ServiceSection";
import RecruitSection from "./RecruitSection";
const Home = () => {
  return (
    <main id="main">
      <VideoSection />
      <ServiceSection />
      <RecruitSection />
    </main>
  );
};
export default Home;
