import LabelComponent from "./LabelComponent";

/* eslint-disable react/prop-types */
const AddressComponent = (props) => {
  return (
    <div className="form-col2">
      <LabelComponent labelName={props.labelName} />
      <div className="form-inputText">
        <input
          type="text"
          className="inputType"
          name="address"
          id="address"
          defaultValue={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export default AddressComponent;
